import React, {useState} from 'react'
import { Box, Tabs, TabList, TabPanel, TabPanels, Tab } from '@chakra-ui/react';
import Ruleset from './Ruleset';
import CampaignStrategy from './CampaignStrategy';

export default function Template({ accountId, inTab = false }) {

  function tabView() {
    return <Box>
      <div style={{ marginTop: '20px' }}></div>
      <Tabs isLazy>
        <TabList>
          <Tab>Ruleset</Tab>
          <Tab>Campaign Strategies</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Ruleset/>
          </TabPanel>
          <TabPanel>
            <CampaignStrategy/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  }
  return inTab ? tabView() : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {tabView()}
    </Box>
  )
}
