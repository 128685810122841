import { useEffect, useState } from "react"
import { Input, InputGroup, InputRightElement, IconButton, useColorModeValue } from '@chakra-ui/react';
import{ SearchIcon } from '@chakra-ui/icons';

export default function SearchBox({ placeholder, value, onChange, onEnter }) {
    const boxBg = useColorModeValue("white", "blackAlpha.100");
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";

    const handleEnter = (arg)=>{
        if(onEnter) {
            onEnter(arg)
        }
    }
    
    const handleKeyDown = async(event) => {
        if (event.key === 'Enter' && onEnter) {
            handleEnter(event.target.value)
        }
    }

    const [v, setV] = useState(value)

    useEffect(() => {
        setV(value)
    }, [value])

    const handleChange = (event) => {
        
        if(onChange) {
            onChange(event.target.value)
        }else{
            setV(event.target.value)
        }
    }

    return(
        <InputGroup width = '300px'>
            <Input color={textColor} bg={boxBg} bgColor={boxBg} _placeholder={{ textColor: textColorSecondary }} style={{ borderRadius: '20px', height:"40px", marginBottom:"10px"}} type='text' placeholder={placeholder} value={v} onChange={handleChange} onKeyDown={handleKeyDown} />
            <InputRightElement children={
                <IconButton borderRadius= '20px' onClick={()=>handleEnter(v)}
                    colorScheme='blue'
                    aria-label='Search database'
                    icon={<SearchIcon />}
                />} 
            />
        </InputGroup>
    )
}