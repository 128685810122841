import API from './api';
import moment from 'moment';
import { DT_FORMAT } from 'constants';

export class PendingReportsService extends API {
  constructor() {
    super('admin/pending_reports');
  }

  async getPendingReports({metaId, accountId, page, perPage, sortColumn, sortDirection, filterSpec, filterText }) {
    let params = this.getPaginationParams(page, perPage, sortColumn, sortDirection, filterSpec, filterText);
    if (accountId) {
      params.account_id = accountId;
    }
    if (metaId) {
      params.meta_id = metaId
    }
    return await this.get({
      params : params
    });
  }

  async updateProcessingStatus(id, processing) {
    return await this.put({
      body: { id: id, processing: processing }
    });
  }

  async deletePendingReport(id) {
    return await this.delete({
      params: { id: id }
    });
  }

  async scheduleReport(config, fromDate, toDate) {
    return await this.post({
      body: { schedule_config: config, from: moment(fromDate).format(DT_FORMAT), to: moment(toDate).format(DT_FORMAT) }
    });
  }
}