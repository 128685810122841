import React, { useState, useEffect } from 'react'
import { AccountsService } from 'services/accounts_service';
import { Box, Button } from '@chakra-ui/react';
import { catchAsync } from 'util';
import Loader from 'components/loader';
import { useWizard } from 'components/wizard';

export function DeactivateCampaignManagementTasks({ accountId }) {
    const service = new AccountsService();
    const [campaignTasksCount, setCampaignTasksCount] = useState();
    const [loading, setLoading] = useState();
    const { nextStep, setCanGoNext } = useWizard();

    const fetchData = catchAsync(async () => {
        setLoading(true)
        const { tasks_count } = await service.configureAccount({ account_id: accountId, flag: 'campaign_tasks' });
        setCampaignTasksCount(tasks_count);
        setLoading(false)
    }, undefined, 'Error fetching campaign management data. Please try again later');

    useEffect(async () => {
        fetchData();
    }, [])

    useEffect(async() => {
        if (campaignTasksCount == 0) {
            nextStep()
        } else {
            setCanGoNext(false)
        }
    }, [campaignTasksCount])

    const handleDeactivateTasks = catchAsync(async () => {
        await service.configureAccount({ account_id: accountId, flag: 'deactivate_campaign_tasks' });
        fetchData();
    }, 'All campaign management tasks deactivated successfully', 'Error deactivating campaign management tasks')

    return (
        <Box margin={2}>
            {loading ? <Loader /> : <div>
                {campaignTasksCount == 0 && <ul><li> No active campaign management tasks exist for this account, you can proceed</li></ul>}
                {campaignTasksCount != 0 &&
                    <Box>
                        <ul><li>{campaignTasksCount} active campaign management tasks exist for this account</li></ul>
                        <Box>
                            <Button onClick={handleDeactivateTasks} colorScheme='teal' marginTop={4}>Deactivate Tasks</Button>
                        </Box>
                    </Box>}
            </div>}
        </Box>
    )
}
