
import API from './api';

export class AuthService extends API {
    constructor(){
    super('admin/auth');
    }
    async getAuthToken(email, password) {
        return await this.post({
            body: {
                email,
                password
            }
        });
    }

    async getUser() {
        return await this.get();
    }
}