import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdGroups, MdHome, MdLock, MdGroup, MdPerson } from "react-icons/md";
import { GiSpanner } from 'react-icons/gi'
import { CgOrganisation } from 'react-icons/cg'
import { FaFileAlt } from "react-icons/fa";
// Admin Imports
import Dashboard from "features/dashboard";
// Auth Imports
import SignInCentered from "features/signIn";
import Users from "features/users";
import Organizations from "features/organizations";
import Organization from "features/organizations/organization";
import Accounts from "features/accounts";
import Account from "features/accounts/account";
import Debugging from "features/debugging";
import Template from "features/Template";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Dashboard,
  },
  {
    name: "Organizations",
    layout: "/admin",
    path: "/organizations",
    icon: <Icon as={CgOrganisation} width="20px" height="20px" color="inherit" />,
    component: Organizations,
    exact: true,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Users,
  },
  {
    name: "Accounts",
    layout: "/admin",
    path: "/accounts",
    icon: <Icon as={MdGroups} width="20px" height="20px" color="inherit" />,
    component: Accounts,
    exact: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/login",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Organizations",
    layout: "/admin",
    path: "/organizations/:orgId/:tabKey?",
    icon: <Icon as={CgOrganisation} width="20px" height="20px" color="inherit" />,
    component: Organization,
    hideFromSideBar: true,
  },
  {
    name: "Accounts",
    layout: "/admin",
    path: "/accounts/:accountId/:tabKey?",
    icon: <Icon as={MdGroup} width="20px" height="20px" color="inherit" />,
    component: Account,
    hideFromSideBar: true,
  },
  {
    name: "Debug",
    layout: "/admin",
    path: "/debug",
    icon: <Icon as={GiSpanner} width="20px" height="20px" color="inherit" />,
    component: Debugging,
  },
  {
    name: "Templates",
    layout: "/admin",
    path: "/templates",
    icon: <Icon as={FaFileAlt} width="20px" height="20px" color="inherit" />,
    component: Template,
  },
];
export default routes;