import React, { useState, useEffect } from 'react'
import { AccountsService } from 'services/accounts_service';
import { Box, Button } from '@chakra-ui/react';
import { catchAsync } from 'util';
import Loader from 'components/loader';
import { useWizard } from 'components/wizard';

export function DeactivateMarketingStream({ accountId, account }) {
  const service = new AccountsService();
  const [marketingStreamDisabled, setmarketingStreamDisabledStatus] = useState(account.marketing_stream_subscriptions ? (account.marketing_stream_subscriptions.length > 0 ? false : true) : true);
  const [loading, setLoading] = useState();
  const { nextStep, setCanGoNext } = useWizard();

  const updateMarketingStream = catchAsync(async () => {
    setLoading(true)
    const accountsService = new AccountsService();
    await accountsService.updateMarketingStream(accountId, false)
    setmarketingStreamDisabledStatus(true)
    setLoading(false)
  }, undefined, 'Error disabling marketing stream. Please try again later');

  useEffect(() => {
    if (!account.marketing_stream_subscriptions || marketingStreamDisabled) {
      if (account.active) {
        setCanGoNext(true)
      } else {
        nextStep();
    }}
    else {
      setCanGoNext(false)
    }
  }, [marketingStreamDisabled])

  
  return (
    <Box margin={2}>
      {loading ? <Loader /> : <div>
        {marketingStreamDisabled && <div>No Active Marketing Stream Subscriptions</div>}
        {!marketingStreamDisabled && <Button onClick={updateMarketingStream} colorScheme='teal' marginTop={4}>Disable Marketing Stream</Button>}
      </div>}
    </Box>
  )
}
