import "assets/css/App.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { useAuth } from 'contexts/auth_context';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "react-datepicker/dist/react-datepicker.css";

export default function App() {
    const { authenticated } = useAuth();

    return <Router>
        <Switch>
            {!authenticated && <Route path={`/auth`} component={AuthLayout} />}
            {authenticated && <Route path={`/admin`} component={AdminLayout} />}
            <Redirect from='/' to={authenticated? '/admin': '/auth'} />
        </Switch>
    </Router>
}