import Swal from 'sweetalert2'

export const get_env = () => {
    if (process.env.REACT_APP_ADBREW_BREWING_STAGE !== undefined) {
        return "brewing"
    } else if (process.env.NODE_ENV === 'development') {
        return "development"
    } else {
        return "production"
    }
}

function convertArrayOfObjectsToCSV({ array, specialKeys }) {

    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = specialKeys ? specialKeys : Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });
    return result;

}

export function downloadCSV(array, filename) {

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV({ array });
    if (csv == null) return;

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}

export function catchAsync(fn, successMessage, failureMessage) {
    return async function (...args) { 
        try {
            await fn(...args);
            if (successMessage) {
                Swal.fire({
                    title: 'Success',
                    text: successMessage,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#3B82F6'
                })
            }
        } catch (error) {
            console.log(error)
            if (failureMessage) {
                Swal.fire({
                    title: 'Error',
                    text: failureMessage,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#3B82F6'
                })
            }
        }
    }
}