import React, { useState, useEffect } from 'react'
import { AccountsService } from 'services/accounts_service';
import { Box, Button } from '@chakra-ui/react';
import { catchAsync } from 'util';
import Loader from 'components/loader';
import { useWizard } from 'components/wizard';

export function DeactivateTargetManagementTasks({ accountId }) {
  const service = new AccountsService();
  const [targetTasksCount, setTargetTasksCount] = useState();
  const [loading, setLoading] = useState();
  const { nextStep, setCanGoNext } = useWizard();

  const fetchData = catchAsync(async () => {
    setLoading(true)
    const { tasks_count } = await service.configureAccount({ account_id: accountId, flag: 'target_tasks' });
    setTargetTasksCount(tasks_count);
    setLoading(false)
  }, undefined, 'Error fetching target management data. Please try again later');

  useEffect(async () => {
    fetchData();
  }, [])

  useEffect(() => {
    if (targetTasksCount == 0) {
      nextStep()
    } else {
      setCanGoNext(false)
    }
  }, [targetTasksCount])

  const handleDeactivateTasks = catchAsync(async () => {
    await service.configureAccount({ account_id: accountId, flag: 'deactivate_target_tasks' });
    fetchData();
  }, 'All target management tasks deactivated successfully', 'Error deactivating target management tasks')
  
  return (
    <Box margin={2}>
      {loading ? <Loader /> : <div>
        {targetTasksCount == 0 && <ul><li>No active target management tasks exist for this account, you can proceed</li></ul>}
        {targetTasksCount != 0 &&
          <Box>
            <ul><li>{targetTasksCount} active target management tasks exist for this account</li></ul>
            <Box>
              <Button onClick={handleDeactivateTasks} colorScheme='teal' marginTop={4}>Deactivate Tasks</Button>
            </Box>
          </Box>}
      </div>}
    </Box>
  )
}
