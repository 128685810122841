import { Box, Button, Flex, useColorModeValue } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import React, { useContext, createContext, useState } from "react";

const wizardContext = createContext();

function useWizard() {
  return useContext(wizardContext);
}

function ProvideWizard({ children }) {
  const data = useProvideWizard();
  return (
    <wizardContext.Provider value={data}>
      {children}
    </wizardContext.Provider>
  )
}

function useProvideWizard() {
  const [canGoNext, setCanGoNext] = useState(true);
  const [canGoBack, setCanGoBack] = useState(true);
  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  return { canGoNext, setCanGoNext, canGoBack, setCanGoBack, nextStep, prevStep, setStep, reset, activeStep };
}

function Wizard({ steps, onComplete }) {
  const boxBg = useColorModeValue("white", "blackAlpha.300");
  const { canGoNext, canGoBack, nextStep, prevStep, activeStep } = useWizard();


  const getActions = () => {
    if (activeStep === steps.length) {
      return (
        <div></div>
      );
    }
    if (activeStep === steps.length - 1) {
      return <Flex width="100%" justify="flex-end">
        <Button
          isDisabled={!canGoNext}
          mx="auto"
          colorScheme='teal'
          onClick={onComplete}
        >
          Submit
        </Button>
      </Flex>
    }

    return <Flex width="100%" justify="center">
      <Button
        isDisabled={activeStep === 0 || !canGoBack}
        mr={4}
        onClick={prevStep}
        variant="ghost"
      >
        Prev
      </Button>
      <Button colorScheme='teal' onClick={nextStep} isDisabled={!canGoNext}>
        Next
      </Button>
    </Flex>
  }

  return (
    <Box>
      <Steps activeStep={activeStep} orientation="vertical" textAlign="start">
        {steps.map(({ label, component }) => (
          <Step label={label} key={label}>
            <Box bg={boxBg} p={2} mt={4} mb={4} borderRadius={10}>
              {component}
            </Box>
          </Step>
        ))}
      </Steps>
      {getActions()}
    </Box>
  );
}

export { Wizard, ProvideWizard, useWizard };