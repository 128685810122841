import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { useFilter } from "contexts/filter_context";
const tablePreferencesContext = createContext();

function useTablePreferences(){
    return useContext(tablePreferencesContext);
}

function ProvideTablePreferences({children}){
    const tablePreferences = useProvideTablePreferences();
    return (
        <tablePreferencesContext.Provider value={tablePreferences}>
            {children}
        </tablePreferencesContext.Provider>
    )
}

const DEFAULT_FILTER_PREF = {
    perPage: 10,
    filterText: ''
}

function useProvideTablePreferences(){
    const [page, setPage] = useState(1) 
    const [sortDetails,setSortDetails] = useState({})
    const {setFilterType, filters, filterKey} = useFilter()
    const [userPreference, setUserPreference] = useState({})
    const [columnPreferences, setColumnPreferences] = useState()
    const [expandPreferences, setExpandPreferences] = useState({})

    useEffect(() => {
        const userPrefRaw = JSON.parse(localStorage.getItem('userPreference')) || {}
        setExpandPreferences(userPrefRaw.expandPreferences ?? {})
        if(filterKey && userPrefRaw[filterKey]) {
            setUserPreference(userPrefRaw[filterKey].filterPagination || DEFAULT_FILTER_PREF)
            setPreferencesToLocal(userPrefRaw[filterKey].filterPagination || DEFAULT_FILTER_PREF)
            setColumnPreferences(userPrefRaw[filterKey].columnPreferences)
        }else {
            setUserPreference(DEFAULT_FILTER_PREF)
            setPreferencesToLocal(DEFAULT_FILTER_PREF)
        }
    },[filterKey])

    const setPreferencesToLocal = (filterPagination, columnPreferences, expandPreferences) => {
        let userPrefRaw = JSON.parse(localStorage.getItem('userPreference')) || {}
        if(expandPreferences) {
            userPrefRaw.expandPreferences = expandPreferences
        }
        if(!filterKey) {
            localStorage.setItem('userPreference',JSON.stringify(userPrefRaw))
            return;
        }
        userPrefRaw[filterKey] = userPrefRaw[filterKey] || {}
        if(filterPagination){
            userPrefRaw[filterKey].filterPagination = filterPagination
        }
        if(columnPreferences){
            userPrefRaw[filterKey].columnPreferences = columnPreferences
        }
        localStorage.setItem('userPreference', JSON.stringify(userPrefRaw))
    }

    const getPreferences = (par) => {
        let userPrefRaw = JSON.parse(localStorage.getItem('userPreference')) || {}
        userPrefRaw[par] = userPrefRaw[par] || {}
        if(userPrefRaw[par].filterPagination)
            userPrefRaw[par].filterPagination = { ...DEFAULT_FILTER_PREF, ...userPrefRaw[par].filterPagination }
        else
            userPrefRaw[par].filterPagination = DEFAULT_FILTER_PREF
        return userPrefRaw[par].filterPagination
    }

    const setFilterText = (text) => {
        setUserPreference(t => ({...t, filterText: text}))
        setPreferencesToLocal({ ...userPreference, filterText : text})
    }

    const handlePageChange = page => {
        setPage(page)
    };

    const handlePerRowsChange = async (newPerPage,page) => {
        setUserPreference(t => ({ ...t, perPage: newPerPage }))
        setPreferencesToLocal({ ...userPreference, perPage: newPerPage })
    };

    const handleSort = async (column, sortDirection) => {
        setSortDetails({ column: column.sortField, direction: sortDirection })
    };

    useEffect(() => {
        setPreferencesToLocal(undefined, columnPreferences, expandPreferences)
    }, [columnPreferences , expandPreferences])

    const isExpanded = (key , value) => {
        return expandPreferences ? expandPreferences[key] ?? true : false
    }
    const updateExpandPreference = (key, value) => { 
        if(!expandPreferences) return
        expandPreferences[key] = value
        setExpandPreferences({ ...expandPreferences })
    }

    const updateColumnPreferences = (preferences) => {
        setColumnPreferences(preferences)
    }

    return {
        page, sortDetails, getPreferences,
        handlePageChange, handlePerRowsChange, handleSort, setFilterText, 
        setFilterType, filters,
        columnPreferences,
        updateColumnPreferences,
        isExpanded,
        updateExpandPreference,
        expandPreferences,
    }
}

export {
    useTablePreferences,
    ProvideTablePreferences
}