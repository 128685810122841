import React from 'react';
import { FormLabel, Grid, GridItem, Box } from '@chakra-ui/react';
import {conditionOptions,operatorOptions} from 'constants';
import {  DeleteIcon, } from '@chakra-ui/icons'
import FormSelect  from 'components/select/FormSelect';
import NumInput from 'components/input/NumInput';

export function Condition({condition,onChange,onDeleteCondition}) {
  
  return (
    <Box margin={4}>
    <Grid templateColumns='repeat(5, 1fr)' gap={3}>
          <GridItem>
          <FormLabel margin={2}>Attribute</FormLabel>
          <FormSelect options={conditionOptions} value={condition.attribute} onChange={(e) => onChange({...condition,attribute:e.value})} />
          </GridItem>
          <GridItem>
          <FormLabel margin={2}>Operator</FormLabel>
          <FormSelect options={operatorOptions} value={condition.operator} onChange={(e) => onChange({...condition,operator:e.value})}/>
          </GridItem>
          <GridItem>
          <FormLabel margin={2}>Value</FormLabel>
          <NumInput value={condition.value} onChange={(e)=>onChange({...condition,value:e})}/>
          </GridItem>
          <GridItem marginTop={10}>
          <DeleteIcon onClick={()=>onDeleteCondition()} />
          </GridItem>
          </Grid>

     </Box>
  )
}

