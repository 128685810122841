import API from './api';

export class ProductDetailsService extends API {
    constructor() {
        super('admin/product_details');
    }

    async scheduleProductDetails(accountId) {
        return await this.post({
            body: { account_id: accountId, refresh_product_details: true }
        });
    }
}
