import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import ModalDateRangePicker from "components/datepicker/modal_date_range_picker";
import ParaTextInput from "components/input/text_area";
import { REPORT_OPTIONS } from "constants";
import { useState } from "react";
import { PendingReportsService } from 'services/pending_reports_service';
import { catchAsync } from 'util';

export default function ScheduleReport({ onComplete, accountId }) {
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const service = new PendingReportsService();
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [input, setInput] = useState([]);

    const handleSubmit = catchAsync(async() => {
        await service.scheduleReport(input, fromDate, toDate);
        if (onComplete) {
            onComplete();
        }
    }, "Reports scheduled successfully", "Error scheduling reports");

    const handleDataRangeChange = (data) => {
        setFromDate(data?.start);
        setToDate(data?.end);
    }

    const handleReportClick = (report) => {
        report = { ...report, account_id: accountId };
        setInput([...input, report]);
    }

    const handleAddAll = () => {
        const reports = REPORT_OPTIONS.map(report => ({ ...report, account_id: accountId }));
        setInput([...input, ...reports]);
    }

    return <Box>
        <ModalDateRangePicker value={{ start: fromDate, end: toDate }} onChange={handleDataRangeChange} />
        <div style={{ marginTop: '20px' }}/>
        <ParaTextInput value={JSON.stringify(input)} onChange={(v) => setInput(JSON.parse(v))} />
        <Button mt={4} colorScheme='teal' onClick={handleSubmit}>Schedule</Button>
        <Button mt={4} ml={4} colorScheme='gray' onClick={handleAddAll}>Add All</Button>
        <Box bg={boxBg} mt={4}>
            {REPORT_OPTIONS.map((report, idx) => <Box key={idx} borderRadius="md" onClick={() => handleReportClick(report)} cursor="pointer" bg="gray.200" p={1} mb={1}>
                {JSON.stringify(report)}
            </Box>)}
        </Box>
    </Box>
}