import React, { useContext, createContext, useState, useEffect } from "react";
import { AuthService } from 'services/auth_service';
 
const authContext = createContext();

function useAuth() {
    return useContext(authContext);
}

function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

const signout = () => {
    let redirect = false;
    if (localStorage.getItem("credentials")) {
        redirect = true;
        localStorage.removeItem("credentials");
    }
    // retain the account info on logout
    // if (localStorage.getItem("account")) {
    //     redirect = true;
    //     localStorage.removeItem("account");
    // }
    if (redirect) {
        window.location = '/auth/login';
    }
}

function useProvideAuth() {
    const service = new AuthService()
    const { accessToken, refreshToken } = JSON.parse(localStorage.getItem("credentials") || "{}");
    const [authenticated, setAuthenticated] = useState((accessToken && refreshToken) ? true : false);
    const [user, setUser] = useState({})
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { user } = await service.getUser();
                setUser(user);
            } catch (e) {
                console.log({ e })
            }
        };
        if (authenticated)
            fetchData();
    }, [authenticated]);

    const signin = async (username, password) => {
        if (username.trim().length === 0 || password.trim().length === 0) {
            throw Error();
        } 
        try {
            const creds = await service.getAuthToken(username, password);
            localStorage.setItem('credentials', JSON.stringify({ accessToken: creds.access, refreshToken: creds.refresh }));
            setAuthenticated(true);
        } catch (err) {
            throw Error(err);
        }
    }
    
    const _signout = () => {
        console.log("SIGNING OUT");
        signout()
        // setAuthenticated(false);
    }

    return {
        signin,
        signout,
        authenticated,
        user,
        signout: _signout,
    }
}


export {
    useAuth,
    ProvideAuth,
    signout,
}
