import API from './api';

export class AutomatedRunsService extends API {
  async getFailedRuns({ runId, accountId, runStatus, page, perPage, sortColumn, sortDirection, filterSpec, filterText }) {
    let params = this.getPaginationParams(page, perPage, sortColumn, sortDirection, filterSpec, filterText);
    if (accountId) params.account_id = accountId;
    if (runStatus) params.run_status = runStatus;
    if (runId) params.run_id = runId
    return await this.get({
      path: 'admin/automated_runs',
      params: params,
    });
  }
}