import React from 'react'
import { Grid, GridItem, FormLabel } from '@chakra-ui/react'
import NumInput from 'components/input/NumInput';

export default function SovConfig({ data, onChange }) {

  return (
    <div>
      <Grid templateColumns='repeat(5, 1fr)' gap={3} mt={3}>
        <GridItem colStart={1} colEnd={3}>
          <FormLabel>Complementary SOV keywords (*)</FormLabel>
          <NumInput placeholder='For each account' value={data.complementary} onChange={(e) => onChange({ ...data, complementary: e })} />
        </GridItem>
        <GridItem colStart={3} colEnd={5}>
          <FormLabel> Price (*)</FormLabel>
          <NumInput placeholder='Per keyword per month' value={data.fee_per_kw_month} onChange={(e) => onChange({ ...data, fee_per_kw_month: e })} />
        </GridItem>
      </Grid>
      <Grid templateColumns='repeat(5, 1fr)' gap={3} mt={3}>
        <GridItem colStart={1} colEnd={3}>
          <FormLabel>Fixed Fee</FormLabel>
          <NumInput placeholder='For each account' value={data.fixed_fee} onChange={(e) => onChange({ ...data, fixed_fee: e })} />
        </GridItem>
        <GridItem colStart={3} colEnd={5}>
          <FormLabel> Allowed Count </FormLabel>
          <NumInput placeholder='Per keyword per month' value={data.allowed_sov_count} onChange={(e) => onChange({ ...data, allowed_sov_count: e })} />
        </GridItem>
      </Grid>
    </div>
  )
}
