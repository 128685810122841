import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import ParaTextInput from "components/input/text_area";
import { SNAPSHOT_OPTIONS } from "constants";
import { useState } from "react";
import { PendingSnapshotsService } from "services/pending_snapshots_service";
import { catchAsync } from 'util';

export default function ScheduleSnapshot({ onComplete, accountId }) {
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const service = new PendingSnapshotsService();
    const [input, setInput] = useState([]);
    
    const handleSubmit = catchAsync(async () => {
        await service.scheduleSnapshot(input);
        if (onComplete) {
            onComplete();
        }
    }, "Snapshots scheduled successfully", "Error scheduling snapshots");

    const handleSnapshotClick = (snapshot) => {
        snapshot = { ...snapshot, account_id: accountId };
        setInput([...input, snapshot]);
    }

    const handleAddAll = () => {
        const snapshots = SNAPSHOT_OPTIONS.map(snapshot => ({ ...snapshot, account_id: accountId }));
        setInput([...input, ...snapshots]);
    }

    return <Box>
        <ParaTextInput value={JSON.stringify(input)} onChange={(v) => setInput(JSON.parse(v))} />
        <Button mt={4} colorScheme='teal' onClick={handleSubmit}>Schedule</Button>
        <Button mt={4} ml={4} colorScheme='gray' onClick={handleAddAll}>Add All</Button>
        <Box bg={boxBg} mt={4}>
            {SNAPSHOT_OPTIONS.map((snapshot, idx) => <Box key={idx} borderRadius="md" onClick={() => handleSnapshotClick(snapshot)} cursor="pointer" bg="gray.200" p={1} mb={1}>
                {JSON.stringify(snapshot)}
            </Box>)}
        </Box>
    </Box>
}