import API from './api';

export class RulesetsService extends API {
    constructor() {
      super('admin/rulesets');
    }

    async cloneRuleset(accountId, rulesetId, rulesetName) {
      return await this.post({
        body: {account_id: accountId, name: rulesetName, ruleset_id: rulesetId },      
    })
    }
}