import API from './api';
export class OrganizationsService extends API{
    async getOrganizations({page,perPage,sortColumn, sortDirection, filterSpec, filterText}) {
        let params = this.getPaginationParams(page, perPage, sortColumn, sortDirection, filterSpec, filterText);
        return await this.get({
            path: 'admin/organizations',
            params
        });
    }

    async getAllOrganizations() {
      return await this.get({
          path: 'admin/organizations',
      });
  }

    
    async getOrganization(orgId){
      return await this.get({
        path : 'admin/organizations',
        params : {org_id : orgId}
      });
    }
    
    async updateOrganization(body) {
      return await this.post({
        path : 'admin/organizations',
        body: body
      });
    }     

    async scheduleBilling(org_id, start_date, end_date) {
      let body = {
        org_id : org_id,
        start_date : start_date,
        end_date : end_date
      }
      return await this.post({
        path : 'admin/organizations',
        body : body
      });
    }

    async initiateOnboarding(organizationName) {
      const url = 'https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTZlMDYzZTA0MzI1MjZlNTUzZDUxMzIi_pc';
      const data = { content: organizationName };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    }
}