import React from "react";
import { FaSearch } from "react-icons/fa";
import {
  Box,
  Button,
  Heading,
  Text,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
} from "@chakra-ui/react";

export const TemplateCard = ({
  template,
  handleEditTemplate,
  handleDeleteTemplate,
}) => {

  function getManagementRulesetText(type) {
    switch (type) {
      case 'target':
        return 'Target Management';
      case 'bid':
        return 'Bid Management';
      case 'sov':
        return 'SOV Management';
      case 'campaign':
        return 'Campaign Management';
      default:
        return '';
    }
  }

  return (
    <Box
      key={template._id}
      borderWidth="1px"
      p="4"
      mb="2"
      style={{
        height: "350px",
        width: "360px",
        background: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "6px",
        padding: "25px"
      }}
    >
      <div>
        <Heading
          as="h4"
          size="md"
          mb="2"
          style={{
            display: "-webkit-box",
            width: "310px",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            height: "72px",
          }}
        >
          {template.template_name}
        </Heading>
        {template.template.type && getManagementRulesetText !== "" && (
          <Badge
            variant="solid"
            colorScheme="blue"
            fontWeight="400"
            p="1"
            style={{
              borderRadius: "6px",
              marginBottom: "10px",
              marginTop: "10px",
              maxWidth: "160px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getManagementRulesetText(template.template.type)}
          </Badge>
        )}
        <Text
          style={{
            display: "-webkit-box",
            maxWidth: "310px",
            WebkitLineClamp: 6,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {template.description}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Flex justifyContent="space-between" alignItems="center" marginTop="10px">
          <Box
            width="10px"
            height="10px"
            borderRadius="50%"
            marginRight="20px"
            backgroundColor={template.is_active === true ? "green" : "red"}
          ></Box>
          <Box>
            <Button onClick={() => handleEditTemplate(template)}>Edit</Button>
          </Box>
          <Box>
            <Button onClick={() => handleDeleteTemplate(template._id)}>
              Delete
            </Button>
          </Box>
        </Flex>
      </div>
    </Box>
  );
};

export const DeleteConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title = "Delete Template",
  message = "Are you sure you want to delete this template?",
  confirmText = "Delete",
  cancelText = "Cancel",
}) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>{cancelText}</Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              {confirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};


export const SearchBox = ({ searchTerm, setSearchTerm }) => {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={<Icon as={FaSearch} color="gray.300" />}
      />
      <Input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        bgColor="white"
        placeholder="Search Template"
        style={{ borderRadius: "20px" }}
      />
    </InputGroup>
  );
};