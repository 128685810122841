import React, { useState, useEffect } from 'react'
import { AccountsService } from 'services/accounts_service';
import { Box, Button } from '@chakra-ui/react';
import FormSelect from 'components/select/FormSelect';
import CustomDataTable from "components/custom_data_table/custom_data_table";
import Loader from 'components/loader';
import { useWizard } from 'components/wizard';
import { catchAsync } from 'util';

export function Rulesets({ accountId }) {
  const service = new AccountsService();
  const [rulesetsToMap, setRulesetsToMap] = useState([]);
  const [rulesetsCount, setRulesetsCount] = useState();
  const [orgAccounts, setOrgAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const { setCanGoNext, nextStep } = useWizard();
  const [loading, setLoading] = useState();

  const fetchData = catchAsync(async () => {
    setLoading(true)
    const { rulesets_count, rulesets_to_map, accounts } = await service.configureAccount({ account_id: accountId, flag: 'rulesets' });
    setRulesetsCount(rulesets_count);
    setRulesetsToMap(rulesets_to_map);
    setOrgAccounts(accounts)
    setLoading(false)
  }, undefined, 'Error fetching data. Please try again later');

  useEffect(async () => {
    fetchData();
  }, [])

  useEffect(() => {
    if (rulesetsCount == 0 && rulesetsToMap.length == 0) {
      nextStep()
    } else {
      setCanGoNext(false)
    }
  }, [rulesetsCount])

  const handleDeactivateRulesets = catchAsync(async () => {
    await service.configureAccount({ account_id: accountId, flag: 'deactivate_rulesets' });
    fetchData()
  }, 'Rulesets deactivated successfully', 'Error deactivating rulesets')

  const handleMapRuleset = catchAsync(async (ruleset_id, account_to_map_id) => {
    await service.configureAccount({ account_id: accountId, flag: 'map_rulesets', account_to_map_id: account_to_map_id, ruleset_id: ruleset_id });
    fetchData();
  }, 'Ruleset mapped successfully', 'Error mapping ruleset')

  const columns = [
    {
      name: "Rulesets Name",
      cell: (row) => <div>{row.name}</div>,
      width: "220px",
    },
    {
      name: "Tasks By Account",
      cell: ({ tasks_count_map }) => <div>
        <ul>
          {Object.keys(tasks_count_map).map((accountName, index) => <li key={index}>{accountName} : {tasks_count_map[accountName]}</li>)}
        </ul>
      </div>,
      width: "220px",
    },
    {
      name: "Account To Map",
      cell: (row) => <FormSelect minWidth='300px' options={orgAccounts.filter(acc => acc._id !== accountId).map(acc => { return { value: acc._id, label: acc.account_name } })} onChange={(e) => setSelectedAccount(e.value)} value={selectedAccount} />,
      width: "440px",
    },
    {
      name: "Confirm",
      cell: (row) => <Button size='sm' colorScheme="teal" onClick={() => handleMapRuleset(row._id, selectedAccount)} >Map</Button>,
      width: "220px",
    }]

  return (
    <Box margin={2}>
      {loading ? <Loader /> : <div>
        {rulesetsCount == 0 && <ul><li>No tasks have rulesets applied, you can proceed</li></ul>}
        {rulesetsCount != 0 && rulesetsToMap.length != 0 &&
          <Box>
            <ul>
              <li>Following rulesets are used in other accounts. Please map following rulesets to another account.</li>
            </ul>

            <CustomDataTable
              progressComponent={<Loader />}
              remotePagination={false}
              columns={columns}
              data={rulesetsToMap}
              keyField="_id"
            />
          </Box>}
        {rulesetsCount != 0 && rulesetsToMap.length == 0 &&
          <Box>
            <ul><li>Found {rulesetsCount} Active Rulesets in this account.</li></ul>
            <Button onClick={handleDeactivateRulesets} colorScheme='teal' marginTop={4}>Deactivate All</Button>
          </Box>}
      </div>}
    </Box>
  )
}
