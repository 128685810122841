import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  Select,
  Alert,
  AlertIcon,
  Grid,
} from "@chakra-ui/react";
import { CampaignStrategyTemplateService } from 'services/strategy_template_service';
import { DeleteConfirmationDialog, TemplateCard, SearchBox } from './commonTemplate';

const strategyService = new CampaignStrategyTemplateService();

export default function CampaignStrategy() {
    const [loading, setLoading] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [formData, setFormData] = useState({
      templateName: '',
      templateDescription: '',
      strategyId: '',
      active: true,
      templateId: null,
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [alert, setAlert] = useState({ status: 'info', title: '', description: '' });
    const [templates, setTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState(null);
    const [templateToEdit, setTemplateToEdit] = useState(false);
  
    const handleCreateClick = () => {
      setFormData({
        templateName: '',
        templateDescription: '',
        strategyId: '',
        active: true,
      });
      setAlert({ status: 'info', description: '' });
      setShowCreateForm(true);
    };
  
    const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleActiveChange = (e) => {
      const { name, value } = e.target;
      const booleanValue = value === 'true';
      setFormData((prevData) => ({
        ...prevData,
        [name]: booleanValue,
      }));
    };

    const isFormValid = () => {
      if (templateToEdit) {
        return (
          formData.templateName.trim() !== "" &&
          formData.templateDescription.trim() !== ""
        );
      }
      return (
        formData.templateName.trim() !== "" &&
        formData.templateDescription.trim() !== "" &&
        formData.strategyId.trim() !== ""
      );
    };
  
    const handleClose = () => {
      setFormData({
        templateName: '',
        templateDescription: '',
        strategyId: '',
        templateId:null,
      })
      setAlert({ status: 'info', description: '' })
      setShowCreateForm(false)
      setFormSubmitted(false)
      setTemplateToEdit(false);
    };

    const handleSubmit = async () => {
      if (isFormValid()) {
        setLoading(true);
        try {
          const response = await await strategyService.createUpdateStrategyTemplate(
            formData.templateName,
            formData.templateDescription,
            formData.strategyId,
            formData.active,
            formData.templateId
          );
    
          if (templateToEdit) {
            setAlert({
              status: "success",
              description: "Campaign Strategy Template edited",
            });
          } else {
            setAlert({
              status: "success",
              description: "Campaign Strategy Template created",
            });
            setFormData({
              templateName: "",
              templateDescription: "",
              strategyId: "",
              active: true,
              templateId: null
            });
          }
          setFormSubmitted(true);
        } catch (error) {
          if (templateToEdit) {
            setAlert({
              status: "error",
              description: "Error editing template",
            });
          } else {
            setAlert({
              status: "error",
              description: "Error creating template",
            });
          }
          setFormSubmitted(true);
        } finally {
          setLoading(false);
        }
      }
    };  

    useEffect(() => {
      const fetchTemplates = async () => {
        setLoading(true);
        try {
          const { templates_list } = await strategyService.getStrategyTemplate();
          setTemplates(templates_list);
        } catch (error) {
          setAlert({
            status: "error",
            description: "Error fetching templates",
          });
        } finally {
          setLoading(false);
        }
      };
  
      fetchTemplates();
    }, [templateToDelete, formSubmitted]);
  
    const handleEditTemplate = (template) => {
      setTemplateToEdit(true)
      setFormData({
        templateName: template.template_name,
        templateDescription: template.description,
        strategyId: null,
        active: template.is_active,
        templateId: template._id
      });
      setAlert({ status: "info", description: "" });
      setShowCreateForm(true);
    };
  
    const handleDeleteTemplate = (template) => {
      setTemplateToDelete(template);
      setDeleteConfirmationOpen(true);
    };
  
    const onDeleteConfirmationClose = () => {
      setDeleteConfirmationOpen(false);
      setTemplateToDelete(null);
    };
  
    const onDeleteConfirmationConfirm = async () => {
      setDeleteConfirmationOpen(false);
      setLoading(true);
      try {
        await strategyService.deleteStrategyTemplate(templateToDelete);
        setAlert({
          status: 'success',
          description: 'Template deleted successfully',
        });
      } catch (error) {
        setAlert({
          status: 'error',
          description: 'Error deleting template',
        });
      } finally {
        setLoading(false);
        setTemplateToDelete(null);
      }
    };

    const filteredTemplates = templates.filter((template) => {
      const { template_name, description } = template;
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return (
        (template_name && template_name.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (description && description.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });
  
    return (
      <Box>
        {!loading && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleCreateClick} colorScheme="blue" style={{ minWidth: "165px" }}>Create Template</Button>
            </div>
            <div style={{ display: 'flex', marginBottom: '10px', maxWidth: '300px' }}>
             <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </div>
          </div>
        )}
        <Modal isOpen={showCreateForm} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
          <ModalHeader>{templateToEdit ? 'Edit Template' : 'Create Template'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {formSubmitted && (
                <Alert
                  status={alert.status}
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderRadius="md"
                  mb={4}
                >
                  <AlertIcon boxSize="40px" />
                  <Box mt={2}>
                    <strong>{alert.title}</strong>
                  </Box>
                  <Box mt={2}>{alert.description}</Box>
                </Alert>
              )}
              <form>
                <div>
                  <label>Template Name</label>
                  <Input
                    type="text"
                    name="templateName"
                    value={formData.templateName}
                    onChange={handleFormChange}
                  />
                </div>
                <div>
                  <label>Template Description</label>
                  <Textarea
                    type="text"
                    name="templateDescription"
                    value={formData.templateDescription}
                    onChange={handleFormChange}
                  />
                </div>
                <div>
                <label>{templateToEdit ? "Strategy ID (Enter strategy ID to Change Payload else leave this empty)" : "Straegy ID (Enter Strategy ID in which Associated rulesets are created from Ruleset Template)"}</label>
                  <Input
                    type="text"
                    name="strategyId"
                    value={formData.strategyId}
                    onChange={handleFormChange}
                  />
                </div>
                <div>
                <label>Active</label>
                  <Select
                    name="active"
                    value={formData.active}
                    onChange={handleActiveChange}
                  >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Select>
              </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isDisabled={!isFormValid()}
                isLoading={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {filteredTemplates.length > 0 && (
        <Grid templateColumns="repeat(auto-fill, minmax(350px, 1fr))" gap={4}>
          {filteredTemplates.map((template) => (
            <TemplateCard
            key={template._id}
            template={template}
            handleEditTemplate={handleEditTemplate}
            handleDeleteTemplate={handleDeleteTemplate}
          />
          ))}
        </Grid>
      )}
      <DeleteConfirmationDialog
          isOpen={deleteConfirmationOpen}
          onClose={onDeleteConfirmationClose}
          onConfirm={onDeleteConfirmationConfirm}
        />
      </Box>
    );
  }  