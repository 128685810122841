import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Button,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Configslab } from './config_slab';
import { OrganizationsService } from 'services/organizations_service';
import { currencyOptions, DEFAULT_BILLING, DEFAULT_SLAB } from 'constants';
import SovConfig from './sov_config';
import NumInput from 'components/input/NumInput';
import FormSelect from 'components/select/FormSelect';
import Swal from 'sweetalert2'
import { DEFAULT_SOV } from 'constants';

export default function ConfigureBilling({ billing, orgId }) {
  const service = new OrganizationsService();
  const [billingDay, setBillingDay] = useState('');
  const [generationDay, setGenerationDay] = useState('')
  const [currency, setCurrency] = useState('');
  const [sovConfig, setSovConfig] = useState({});
  const [sovHourlyConfig, setSovHourlyConfig] = useState({});
  const [accountSlabs, setAccountSlabs] = useState([]);
  const [overallSlabs, setOverallSlabs] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [expandedOverallIndex, setExpandedOverallIndex] = useState(-1);

  useEffect(async () => {
    setBillingDay(billing.day_of_month);
    setGenerationDay(billing.generation_day)
    setCurrency(billing.currency);
    setSovConfig(billing.sov_config ?? DEFAULT_SOV);
    setSovHourlyConfig(billing.sov_config_hourly ?? DEFAULT_SOV);
    setAccountSlabs(billing.account_slabs);
    setOverallSlabs(billing.overall_slabs);
  }, []);

  const handleAddAccountSlabs = () => {
    let slab = { ...DEFAULT_SLAB, name: `Slab ${accountSlabs.length + 1}` };
    setAccountSlabs([...accountSlabs, slab]);
  }
  const handleAddOverAllSlabs = () => {
    let slab = { ...DEFAULT_SLAB, name: `Slab ${accountSlabs.length + 1}` };
    setOverallSlabs([...overallSlabs, slab]);
  }

  const handleDeleteAccountSlab = (index) => {
    const slabs = [...accountSlabs];
    slabs.splice(index, 1);
    setAccountSlabs(slabs);
  }

  const handleDeleteOverallSlab = (index) => {
    const slabs = [...overallSlabs];
    slabs.splice(index, 1);
    setOverallSlabs(slabs);
  }

  const handleAccountSlabChange = (index, value) => {
    const slabs = [...accountSlabs];
    slabs[index] = value;
    setAccountSlabs(slabs);
  }

  const handleOverallSlabChange = (index, value) => {
    const slabs = [...overallSlabs];
    slabs[index] = value;
    setOverallSlabs(slabs);
  }

  const handleExpanded = (index) => {
    if (index == expandedIndex) {
      setExpandedIndex(-1);
    }
    else {
      setExpandedIndex(index);
    }
  }

  const handleOverallExpanded = (index) => {
    if (index == expandedOverallIndex) {
      setExpandedOverallIndex(-1);
    }
    else {
      setExpandedOverallIndex(index);
    }
  }

  async function onSubmit() {
    try {
      const body = {
        day_of_month: billingDay,
        account_slabs: accountSlabs,
        overall_slabs: overallSlabs,
        currency: currency,
        sov_config: sovConfig,
        sov_config_hourly: sovHourlyConfig,
        generation_day: generationDay
      };

      await service.updateOrganization({ org_id: orgId, billing: body });
      Swal.fire({
        title: 'Success',
        text: 'Billing configuration updated successfully',
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#3B82F6'
      })
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Error in updating billing configuration',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#3B82F6'
      })
    }

  }


  return (
    <Box>
      <Grid templateColumns='repeat(5, 1fr)' gap={3} mt={4}>
        <GridItem>
          <FormLabel>Billing day(*)</FormLabel>
          <NumInput placeholder='Between (3-28)' value={billingDay} onChange={(e) => setBillingDay(e)} />
        </GridItem>
        <GridItem colStart={2}>
          <FormLabel>Bill Generation Day(*)</FormLabel>
          <NumInput placeholder='Between (3-28)' value={generationDay} onChange={(e) => setGenerationDay(e)} />
        </GridItem>
        <GridItem colStart={3}>
          <FormLabel>Currency(*)</FormLabel>
          <FormSelect options={currencyOptions} value={currency} onChange={(e) => setCurrency(e.value)} />
        </GridItem>
      </Grid>

      <hr style={{ marginTop: '15px', marginBottom: '15px', height: '2px' }} />

      <Grid>
        <h3>Account slabs</h3>
        <div>{accountSlabs.map((slab, index) => <div key={index}><Configslab slab={slab} onDelete={() => handleDeleteAccountSlab(index)} onChange={(newSlab) => handleAccountSlabChange(index, newSlab)} isExpanded={index == expandedIndex} onExpand={() => handleExpanded(index)} /></div>)}</div>
      </Grid>
      <Button onClick={handleAddAccountSlabs} mt={4}> + Add Account Slab</Button>

      <hr style={{ marginTop: '15px', marginBottom: '15px', height: '2px' }} />

      <Grid>
        <h3>Overall slabs</h3>
        <div>{overallSlabs.map((slab, index) => <div key={index}><Configslab slab={slab} onDelete={handleDeleteOverallSlab} onChange={(newSlab) => handleOverallSlabChange(index, newSlab)} isExpanded={index == expandedOverallIndex} onExpand={() => handleOverallExpanded(index)} /></div>)}</div>
      </Grid>
      <Button onClick={handleAddOverAllSlabs} mt={4}> + Add Overall Slab</Button>

      <hr style={{ marginTop: '15px', marginBottom: '15px', height: '2px' }} />
      <h3>Daily Configuration</h3>
      <SovConfig data={sovConfig} onChange={(newChange) => setSovConfig(newChange)} />
      <hr style={{ marginTop: '15px', marginBottom: '15px', height: '2px' }} />
      <h3>Hourly Configuration</h3>
      <SovConfig data={sovHourlyConfig} onChange={(newChange) => setSovHourlyConfig(newChange)} />
      <hr style={{ marginTop: '15px', marginBottom: '15px', height: '2px' }} />
      <Button colorScheme='teal' mt={2} onClick={onSubmit}>Configure Billing</Button>
    </Box >
  )
}
