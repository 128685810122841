import React, { useEffect, useState } from 'react';
import { Input, useColorModeValue } from '@chakra-ui/react';

export default function TextInput({ placeholder, onChange, value }) {
  const boxBg = useColorModeValue("white", "blackAlpha.100");
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const [v, setV] = useState('');

  useEffect(() => {
    setV(value ?? '');
  }, [value]);

  const handleChange = (e) => {
    const { value } = e.target
    setV(value)
    if (onChange) onChange(value)
  }

  return (
    <Input color={textColor} bg={boxBg} bgColor={boxBg} _placeholder={{ textColor: textColorSecondary }} type='text' placeholder={placeholder} value={v} onChange={handleChange} />
  )
}
