import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import TextInput from "components/input/TextInput";
import { useState } from "react";
import { catchAsync } from 'util';
import { RulesetsService } from "services/rulesets_service";
import Loader from 'components/loader';


export default function CloneRuleset({ onComplete, accountId }) {
    const service = new RulesetsService();
    const [rulesetId, setRulesetId] = useState('')
    const [rulesetName, setRulesetName] = useState('')
    const [loading, setLoading] = useState();


    const handleSubmit = catchAsync(async() => {
        setLoading(true)
        onComplete()
        await service.cloneRuleset(accountId, rulesetId, rulesetName)
        setLoading(false)
    }, "Cloned rulesets into account successfully", "Error cloning rulesets");

    return <Box>
        {loading ? <Loader /> : <div>
            <div>Ruleset Name</div>
            <TextInput onChange={(e)=>{setRulesetName(e)}} />
            <div>Ruleset ID</div>
            <TextInput onChange={(e)=>{setRulesetId(e)}} />
            <Button mt={4} colorScheme='teal' onClick={handleSubmit}>Submit</Button>
        </div>}
        </Box>
}