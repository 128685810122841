import API from './api';

export class RulesetTemplateService extends API {
    constructor() {
      super('admin/ruleset_template');
    }

    async createUpdateRulesetTemplate(templateName, templateDescription, rulesetId, active, templateId) {
      return await this.post({
        body: { template_name: templateName, template_description: templateDescription, ruleset_id: rulesetId, is_active: active, _id: templateId },      
    })
    }
    async getRulesetTemplate() {
      return await this.get();
    }
    async deleteRulesetTemplate(templateId) {
      return await this.delete({
        params: { template_id: templateId }
      });
    }
}