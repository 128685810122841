import React from 'react'
import { AccountsService } from 'services/accounts_service';
import { DeactivateStrategies } from './strategies';
import { DisableDaypartingCampaigns } from './dayparting_campaigns';
import { Rulesets } from './rulesets';
import { DeactivateBidManagementTasks } from './bid_management';
import { DeactivateTargetManagementTasks } from './target_management';
import { DeactivateMarketingStream } from './marketing_stream';
import { ProvideWizard, useWizard } from 'components/wizard';
import { Wizard } from 'components/wizard';
import DeactivateBudgetTasks from './budget_tasks';
import { DeactivateCampaignManagementTasks } from './campaign_management';
import { catchAsync } from 'util';

function WrappedDeactivate({accountId, account}) {
  const service = new AccountsService();
  const { nextStep } = useWizard();

  const steps = [
    {
      label: 'Disable Budget Tasks',
      component: <DeactivateBudgetTasks accountId={accountId} />,
    },
    {
      label: 'Disable Dayparting Strategies',
      component: <DeactivateStrategies accountId={accountId} />
    },
    {
      label: 'Disable Dayparting Campaigns',
      component: <DisableDaypartingCampaigns accountId={accountId} />
    },
    {
      label: 'Disable Rulesets',
      component: <Rulesets accountId={accountId} />
    },
    {
      label: 'Disable Bid Management Tasks',
      component: <DeactivateBidManagementTasks accountId={accountId} />
    },
    {
      label: 'Disable Target Management Tasks',
      component: <DeactivateTargetManagementTasks accountId={accountId} />
    },
    {
      label: 'Disable Campaign Management Tasks',
      component: <DeactivateCampaignManagementTasks accountId={accountId} />
    },
    {
      label: 'Disable Marketing Stream',
      component: <DeactivateMarketingStream accountId={accountId} account={account} />
    }
  ]

  const onComplete = catchAsync(async () => {
    await service.configureAccount({ account_id: accountId, flag: 'deactivate_account' });
    nextStep();
  }, 'Account deactivated successfully', 'Error deactivating account')

  return (
    <Wizard steps={steps} onComplete={onComplete} />
  )
}

export function DeactivateAccount(props) {
  return <ProvideWizard>
    <WrappedDeactivate {...props} />
  </ProvideWizard>
}