import React, { useState, useEffect } from 'react'
import { AccountsService } from 'services/accounts_service';
import { Box, Button } from '@chakra-ui/react';
import { catchAsync } from 'util';
import Loader from 'components/loader';
import { useWizard } from 'components/wizard';

export function DeactivateBidManagementTasks({ accountId }) {
  const service = new AccountsService();
  const [bidTasksCount, setBidTasksCount] = useState();
  const [loading, setLoading] = useState();
  const { nextStep, setCanGoNext } = useWizard();
  
  const fetchData = catchAsync(async () => {
    setLoading(true)
    const { tasks_count } = await service.configureAccount({ account_id: accountId, flag: 'bid_tasks' });
    setBidTasksCount(tasks_count);
    setLoading(false)
  }, undefined, 'Error fetching bid management data. Please try again later');

  useEffect(async () => {
    fetchData();
  }, [])

  useEffect(() => {
    if (bidTasksCount == 0) {
      nextStep()
    } else {
      setCanGoNext(false)
    }
  }, [bidTasksCount])

  const handleDeactivateTasks = catchAsync(async () => {
    await service.configureAccount({ account_id: accountId, flag: 'deactivate_bid_tasks' });
    fetchData();
  }, 'All bid management tasks deactivated successfully', 'Error deactivating bid management tasks')

  return (
    <Box margin={2}>
      {loading ? <Loader /> : <div>
        {bidTasksCount == 0 && <ul><li>No active bid management tasks exist for this account, you can proceed</li></ul>}
        {bidTasksCount != 0 &&
          <Box>
            <ul><li>{bidTasksCount} active bid management tasks exist for this account</li></ul>
            <Box>
              <Button onClick={handleDeactivateTasks} colorScheme='teal' marginTop={4}>Deactivate Tasks</Button>
            </Box>
          </Box>}
      </div>}
    </Box>
  )
}
