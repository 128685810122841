import { ChevronDownIcon, ChevronRightIcon, DeleteIcon } from "@chakra-ui/icons";
import { FormLabel, FormControl, Box, Collapse, GridItem, Grid } from "@chakra-ui/react";
import TextInput from 'components/input/TextInput';


export default function ReportConfig({ label, reportConfig, onChange, isExpanded, onExpand, onDelete }) {
    function handleKeyChange(key, value) {
        onChange({ ...reportConfig, [key]: value })
    }

    return (
        <Box mt={2} padding={2} maxW='900px' borderWidth='2px'>
            {!isExpanded && <Box onClick={onExpand} margin={2}><FormLabel><ChevronRightIcon marginRight={1} />{label}</FormLabel></Box>}
            {isExpanded &&
                <Grid templateColumns='repeat(5, 1fr)' gap={4} margin={2} >
                    <GridItem >
                        <FormLabel onClick={onExpand}><ChevronDownIcon margin={1} />{label}</FormLabel>
                    </GridItem>
                    <GridItem colStart={6}>
                        <DeleteIcon onClick={onDelete} />
                    </GridItem>
                </Grid>}
            <Collapse in={isExpanded}>
                <FormControl >
                    <FormLabel margin={3}>Report Type</FormLabel>
                    <TextInput spacing={4} type='text' placeholder='Default' value={reportConfig.report_type} onChange={(e) => handleKeyChange('report_type', e)} />
                    <FormLabel margin={3} >Report Path</FormLabel>
                    <TextInput type='text' placeholder='sampaya.xlsx' value={reportConfig.report_path} onChange={(e) => handleKeyChange('report_path', e)} />
                    <FormLabel margin={3} >Report Handler</FormLabel>
                    <TextInput type='text' placeholder='sampaya' value={reportConfig.report_handler} onChange={(e) => handleKeyChange('report_handler', e)} />
                </FormControl>
            </Collapse>

        </Box>
    )
}
