
import { Icon, Switch } from '@chakra-ui/react';
import CustomDataTable from 'components/custom_data_table/custom_data_table';
import { MdCircle } from 'react-icons/md';
import { AccountsService } from 'services/accounts_service';
import { catchAsync } from 'util';

export default function MarketingStream({ data, forceRefresh=()=>{} }) {

    const columns = [
        {
            name: "Active",
            cell: ({ status }) => (
                <Icon
                    as={MdCircle}
                    width="8px"
                    height="8px"
                    color={status == 'ACTIVE' ? "green.400" : ['PROVISIONING', 'PENDING_CONFIRMATION'].includes(status) ? "orange" : "red"}
                    margin={2}
                />
            ),
            width: "90px",
            sortable: true,
            selector: (row) => row.status,
        },
        {
            name: "Name",
            cell: ({ dataset_id }) => <div>{dataset_id}</div>,
            width: "220px",
            selector: (row) => row.dataset_id,
        },
        {
            name: "Subscription Id",
            cell: ({ subscription_id }) => <div>{subscription_id}</div>,
            width: "220px",
            selector: (row) => row.subscription_id,
        }
    ]

    return <CustomDataTable
        columns={columns}
        data={data}
        keyField="subscriptionId"
        pagination
    />
}