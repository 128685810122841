import React, {useState} from 'react'
import PendingReports from 'features/pending_reports';
import PendingSnapshots from 'features/pending_snapshots';
import AutomatedRuns from 'features/automated_runs';
import ReportValidations from 'features/report_validations';
import { Box, Tabs, TabList, TabPanel, TabPanels, Tab } from '@chakra-ui/react';
import DropdownButton from 'components/buttons/dropdown_button';
import BasicModal from 'components/modals/basic';
import ScheduleReport from 'features/pending_reports/schedule_report';
import ScheduleSnapshot from 'features/pending_snapshots/schedule_snapshot';
import ScheduleReportValidations from 'features/report_validations/schedule_report_validations';

export default function Debugging({ accountId, inTab = false }) {
  const [showScheduleReportModal, setShowScheduleReportModal] = useState(false);
  const [showScheduleSnapshotModal, setShowScheduleSnapshotModal] = useState(false);
  const [showScheduleReportValidationModal, setShowScheduleReportValidationModal] = useState(false);


  const actions = [
    { label: 'Schedule Report', onClick: () => setShowScheduleReportModal(true) },
    { label: 'Schedule Snapshot', onClick: () => setShowScheduleSnapshotModal(true) },
    {label: 'Schedule Report Validation', onClick: () => setShowScheduleReportValidationModal(true)}
  ]

  function tabView() {
    return <Box>
      <DropdownButton label="Actions" actions={actions} />
      <div style={{ marginTop: '20px' }}></div>
      <Tabs isLazy>
        <TabList>
          <Tab>Failed Automated Runs</Tab>
          <Tab>Pending Reports</Tab>
          <Tab>Pending Snapshots</Tab>
          <Tab>Report Validations</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AutomatedRuns accountId={accountId} />
          </TabPanel>
          <TabPanel>
            <PendingReports accountId={accountId} />
          </TabPanel>
          <TabPanel>
            <PendingSnapshots accountId={accountId} />
          </TabPanel>
          <TabPanel>
            <ReportValidations accountId={accountId} />
          </TabPanel>
        </TabPanels>
        <BasicModal size="5xl" title="Schedule Reports" isOpen={showScheduleReportModal} onClose={() => setShowScheduleReportModal(false)}>
          <ScheduleReport accountId={accountId} onComplete={() => setShowScheduleReportModal(false)} />
        </BasicModal>
        <BasicModal size="5xl" title="Schedule Snapshots" isOpen={showScheduleSnapshotModal} onClose={() => setShowScheduleSnapshotModal(false)}>
          <ScheduleSnapshot accountId={accountId} onComplete={() => setShowScheduleSnapshotModal(false)} />
        </BasicModal>
        <BasicModal size="5xl" title="Schedule Report Validations" isOpen={showScheduleReportValidationModal} onClose={() => setShowScheduleReportValidationModal(false)}>
          <ScheduleReportValidations accountId={accountId} onComplete={() => setShowScheduleReportValidationModal(false)} />
        </BasicModal>
      </Tabs>
    </Box>
  }
  return inTab ? tabView() : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {tabView()}
    </Box>
  )
}
