import React from 'react';
import { InputGroup } from '@chakra-ui/react';
import TextInput from './TextInput';

export default function InlineTextEdit({ placeholder, onChange, value }) {
    return (
        <InputGroup width='300px'>
            <TextInput placeholder={placeholder} onChange={onChange} value={value} />
        </InputGroup>
      )
}