import {
  Box,
  FormLabel,
  RadioGroup,
  HStack,
  Radio,
  Grid,
  GridItem,
  Button,
  Collapse,
} from '@chakra-ui/react';
import { DeleteIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import React, { useState } from 'react';
import { DEFAULT_CONDITION } from 'constants';
import TextInput from 'components/input/TextInput';
import NumInput from 'components/input/NumInput';
import { Condition } from './condition';

export function Configslab({ slab, onDelete, onChange, isExpanded, onExpand }) {

  const [conditions, setConditions] = useState(slab.conditions);
  const handleAddCondition = () => {
    setConditions([...conditions, DEFAULT_CONDITION]);
  };

  const handleConditionChange = (condition, index) => {
    const newConditions = [...conditions];
    newConditions[index] = condition;
    setConditions(newConditions);
    onChange({ ...slab, conditions: newConditions });
  };

  const handleDeleteCondition = (index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
    onChange({ ...slab, conditions: newConditions });
  }

  const handleSlabKeyChange = (key, value) => {
    onChange({ ...slab, [key]: value });
  }

  return (
    <Box mt={2} maxW='900px' borderWidth='2px'>
      {!isExpanded && <Box onClick={onExpand} margin={2}><FormLabel><ChevronRightIcon marginRight={1} />{slab.name}</FormLabel></Box>}
      {isExpanded &&
        <Grid templateColumns='repeat(5, 1fr)' gap={4} margin={2} >
          <GridItem >
            <FormLabel onClick={onExpand}><ChevronDownIcon margin={1} />{slab.name}</FormLabel>
            <TextInput type='text' value={slab.name} onChange={(e) => handleSlabKeyChange('name', e)} />
          </GridItem>
          <GridItem colStart={6}>
            <DeleteIcon onClick={() => onDelete()} />
          </GridItem>
        </Grid>}

      <Collapse in={isExpanded} animateOpacity>
        <Grid templateColumns='repeat(5, 1fr)' gap={4}>
          <GridItem margin={4}>
            <FormLabel>Lower Bound(*)</FormLabel>
            <NumInput value={slab.lower_bound} onChange={(e) => handleSlabKeyChange('lower_bound', e)} />
          </GridItem>
          <GridItem colStart={3} colEnd={4} margin={4}>
            <FormLabel>Upper Bound</FormLabel>
            <NumInput value={slab.upper_bound} onChange={(e) => handleSlabKeyChange('upper_bound', e)} />
          </GridItem>
        </Grid>

        <FormLabel margin={4}> Which metric is this slab based on?</FormLabel>
        <RadioGroup value={slab.metric} margin={4} onChange={(e) => handleSlabKeyChange('metric', e)}>
          <HStack spacing='24px'>
            <Radio value='spend' >AdSpend</Radio>
            <Radio value='sales'>AdSales</Radio>
          </HStack>
        </RadioGroup>
        <Grid templateColumns='repeat(5, 1fr)' gap={4} >
          <GridItem margin={4}>
            <FormLabel>Minimum fee for this slab(*)</FormLabel>
            <NumInput value={slab.minimum_fee} onChange={(e) => handleSlabKeyChange('minimum_fee', e)} />
          </GridItem>
          <GridItem colStart={3} colEnd={5} margin={4}>
            <FormLabel >Fixed fee for this slab(*)</FormLabel>
            <NumInput value={slab.fee.fixed_fee} onChange={(e) => handleSlabKeyChange('fee', { ...slab.fee, fixed_fee: e })} />
          </GridItem>
        </Grid>
        <Grid templateColumns='repeat(5, 1fr)' gap={4} margin={4}>
          <GridItem>
            <FormLabel >Percentage fee</FormLabel>
            <NumInput placeholder='(0-100)' size='md' value={slab.fee.percentage} onChange={(e) => handleSlabKeyChange('fee', { ...slab.fee, percentage: e })} />
          </GridItem>
        </Grid>

        <FormLabel margin={4}> Which metric is this percentage based on?(*)</FormLabel>
        <RadioGroup value={slab.fee.metric} margin={4} onChange={(e) => handleSlabKeyChange('fee', { ...slab.fee, metric: e })}>
          <HStack spacing='24px'>
            <Radio value='spend'>AdSpend</Radio>
            <Radio value='sales'>AdSales</Radio>
          </HStack>
        </RadioGroup>

        <FormLabel margin={4}>Conditions</FormLabel>
        <div>{conditions.map((condition, index) => <div key={index}><Condition condition={condition} onChange={(newCondition) => handleConditionChange(newCondition, index)} onDeleteCondition={() => { handleDeleteCondition(index) }} /></div>)}</div>
        <Button onClick={handleAddCondition} margin={4}> + Add Condition</Button>
      </Collapse>
    </Box>
  )
}
