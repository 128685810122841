import React, { useState, useEffect } from 'react'
import { Box, Button, Spinner } from '@chakra-ui/react';
import FormSelect from 'components/select/FormSelect';
import { AccountsService } from 'services/accounts_service';
import CustomDataTable from "components/custom_data_table/custom_data_table";
import { useWizard } from 'components/wizard';
import { catchAsync } from 'util';
import Loader from 'components/loader';

export function DeactivateStrategies({ accountId }) {
  const service = new AccountsService();
  const [strategiesToMap, setStrategiesToMap] = useState([]);
  const [strategiesCount, setStrategiesCount] = useState();
  const [orgAccounts, setOrgAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const { setCanGoNext, nextStep } = useWizard();
  const [loading, setLoading] = useState();

  const fetchData = catchAsync(async () => {
    setLoading(true)
    const { strategies_count, strategies_to_map, accounts } = await service.configureAccount({ account_id: accountId, flag: 'dayparting_strategies' });
    setStrategiesCount(strategies_count);
    setStrategiesToMap(strategies_to_map);
    setOrgAccounts(accounts)
    setLoading(false)
  }, undefined, 'Error fetching strategies data. Please try again later');

  useEffect(async () => {
    fetchData()
  }, [])

  useEffect(() => {
    if (strategiesCount == 0 && strategiesToMap.length == 0) {
      nextStep()
    } else {
      setCanGoNext(false)
    }
  }, [strategiesCount])

  const handleDeactivateStrategies = catchAsync(async () => {
    await service.configureAccount({ account_id: accountId, flag: 'deactivate_strategies' });
    fetchData()
  }, 'Strategies deactivated successfully', 'Error deactivating strategies')

  const handleMapStrategy = catchAsync(async (strategyId, accountToMapId) => {
    await service.configureAccount({ account_id: accountId, flag: 'map_dayparting_strategies', account_to_map_id: accountToMapId, strategy_id: strategyId });
    fetchData()
  }, 'Strategy mapped successfully', 'Error mapping strategy')

  const columns = [
    {
      name: "Strategies Name",
      cell: (row) => <div>{row.name}</div>,
      width: "220px",
    },
    {
      name: "Tasks By Account",
      cell: ({ tasks_count_map }) => <div>
        <ul>
          {Object.keys(tasks_count_map).map((accountName, index) => <li key={index}>{accountName} : {tasks_count_map[accountName]}</li>)}
        </ul>
      </div>,
      width: "220px",
    },
    {
      name: "Account To Map",
      cell: (row) => <FormSelect minWidth='300px' options={orgAccounts.filter(acc => acc._id !== accountId).map(acc => { return { value: acc._id, label: acc.account_name } })} onChange={(e) => setSelectedAccount(e.value)} value={selectedAccount} />,
      width: "440px",
    },
    {
      name: "Confirm",
      cell: (row) => <Button size='sm' colorScheme="teal" onClick={() => handleMapStrategy(row._id, selectedAccount)} >Map</Button>,
      width: "220px",
    },
  ]

  return (
    <Box margin={2}>
      {loading ? <Loader /> : <div>
        {strategiesCount == 0 && <ul><li>No Active Strategies, you can proceed.</li></ul>}
        {strategiesCount != 0 && strategiesToMap.length != 0 &&
          <Box>
            <ul>
              <li>Following strategies are used in other accounts. Please map following strategies to another account.</li>
            </ul>
            <CustomDataTable
              progressComponent={<Loader />}
              remotePagination={false}
              columns={columns}
              data={strategiesToMap}
              keyField="_id"
            />
          </Box>}
        {strategiesCount != 0 && strategiesToMap.length == 0 &&
          <Box>
            <ul>
              <li>Found {strategiesCount} Active Strategies in this account.</li>
            </ul>
            <Button onClick={handleDeactivateStrategies} colorScheme='teal' marginTop={4}>Deactivate All</Button>
          </Box>}
      </div>}
    </Box>
  )
}
