import React from "react";
import ReactDOM from "react-dom";
import theme from "theme/theme";
import { ProvideAuth } from "contexts/auth_context";
import App from "App";
import { ProvidePageState } from "contexts/page_state_context";
import { ProvideTablePreferences } from "contexts/table_preferences_contex";
import { ProvideFilter } from "contexts/filter_context";
import { ChakraProvider } from '@chakra-ui/react';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ProvidePageState>
        <ProvideAuth>
          <ProvideFilter>
            <ProvideTablePreferences>
              <App />
            </ProvideTablePreferences>
          </ProvideFilter>
        </ProvideAuth>
      </ProvidePageState>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);