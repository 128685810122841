import API from './api';

export class PendingSnapshotsService extends API {
  constructor() {
    super('admin/pending_snapshots');
  }

  async getPendingSnapshots({ metaId, accountId, page, perPage, sortColumn, sortDirection, filterSpec, filterText }) {
    let params = this.getPaginationParams(page, perPage, sortColumn, sortDirection, filterSpec, filterText);
    if (accountId) {
      params.account_id = accountId;
    }
    if (metaId) {
      params.meta_id = metaId;
    }
    return await this.get({
      params : params
    });
  }

  async updateProcessingStatus(id, processing) {
    return await this.put({
      body: { id: id, processing: processing }
    });
  }

  async deletePendingReport(id) {
    return await this.delete({
      params: { id: id }
    });
  }

  async scheduleSnapshot(config) {
    return await this.post({
      body: { schedule_config: config }
    });
  }
}
