import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'

export default function BasicModal({ title, children, footer, isOpen, onClose, size = '2xl' }) {
    return (
        <Modal size={size} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {children}
                </ModalBody>
                {footer && <ModalFooter>
                    {footer}
                </ModalFooter>}
            </ModalContent>
        </Modal>
    )
}