import DataTable, { createTheme } from 'react-data-table-component';
import { useTablePreferences } from 'contexts/table_preferences_contex';

export default function CustomDataTable({ filterKey = '',selectableRowDisabled = (row) => row.aggregate, remotePagination, filterColumns = ()=>true,editColumnsEnabled=false, ...props}) {
    const { getPreferences, handlePageChange, handlePerRowsChange, handleSort } = useTablePreferences()
    const { perPage } = getPreferences(filterKey)

    createTheme('adbrewCustomTableTheme', {
        text: {
            primary: '#111'
        },
        striped: {
            default: 'rgba(4,169,245,.05)',
            text: '#111'
        },
        highlightOnHover: {
            default: '#e3f2fd'
        }
    })

    let defaultProps = {
        fixedHeader: true,
        highlightOnHover: true,
        noHeader: true,
        striped: true,
        fixedHeaderScrollHeight: '75vh',
        //customStyles: customDataTableStyle,
        selectableRowDisabled: selectableRowDisabled,
        selectableRowsHighlight: true,
        theme:"adbrewCustomTableTheme",
        //conditionalRowStyles: aggregateRowStyle,
        ...props
    }

    if(remotePagination) {
        defaultProps = {
            ...defaultProps,
            pagination: true,
            paginationServer: true,
            paginationPerPage: perPage,
            onChangeRowsPerPage: handlePerRowsChange,
            onChangePage: handlePageChange,
            onSort: handleSort,
            sortServer: true,
        }
    }

    return <div>
        <DataTable
            {...defaultProps}
            columns={defaultProps.columns}
        />
    </div>
}