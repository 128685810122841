import { signout } from 'contexts/auth_context';
import { get_env } from 'util';

class API {
    BASE_URL_MAP = { "brewing": "http://brewing.app.adbrew.io:8000", "development": "http://localhost:8000", "production": "https://api.adbrew.io" }
    BASE_APP_URL_MAP = { "brewing": "http://brewing.app.adbrew.io", "development": "http://localhost:3000", "production": "https://app.adbrew.io" }

    BASE_URL = this.BASE_URL_MAP[get_env()]
    BASE_APP_URL = this.BASE_APP_URL_MAP[get_env()]

    // BASE_URL = "https://api.adbrew.io"
    // BASE_APP_URL = "https://app.adbrew.io"

    constructor(path) {
        this.path = path;
    }

    getParams(params) {
        // remove null and undefined values from params
        params = Object.entries(params).filter(([key, value]) => value !== null && value !== undefined).reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});
        return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    }

    getHeaders = (notJson) => {
        var myHeaders = new Headers();
        if (!notJson) myHeaders.append("Content-Type", "application/json");
        const { accessToken } = JSON.parse(localStorage.getItem("credentials") || "{}");
        const { id } = JSON.parse(localStorage.getItem("account") || "{}");
        const mid = localStorage.getItem("mid");
        myHeaders.append("Authorization", "JWT " + accessToken);
        if (id)
            myHeaders.append("X-ACCOUNT-ID", id);
        if (mid)
            myHeaders.append("X-M-ID", mid);
        return myHeaders;
    };

    /**
     * Make a GET request to the API.
     * @param {path: string, params: any}
     * @returns 
     */
    async get({ path, params = {} } = {}) {
        return fetch(`${this.BASE_URL}/${path ?? this.path}/?${this.getParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(),
        })
            .then(this.handleResponse)
            .then((data) => {
                return data;
            });
    }

    /**
     * Make a POST request to the API.
     * @param {path: string, body: any}
     * @returns 
     */
    async post({ path, body = {} }) {
        // trailing slash is required for POST requests
        return fetch(`${this.BASE_URL}/${path ?? this.path}/`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(body),
        })
            .then(this.handleResponse)
            .then((data) => {
                return data;
            });
    }

    /**
     * Make a PUT request to the API.
     * @param {path: string, body: any}
     * @returns 
     */
    async put({ path, body = {} }) {
        return fetch(`${this.BASE_URL}/${path ?? this.path}/`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(body),
        })
            .then(this.handleResponse)
            .then((data) => {
                return data;
            });
    }

    /**
     * Make a DELETE request to the API.
     * @param {path: string, params }
     * @returns 
     */
    async delete({ path, params = {}, body = {} }) {
        return fetch(`${this.BASE_URL}/${path ?? this.path}/?${this.getParams(params)}`, {
            method: "DELETE",
            headers: this.getHeaders(),
            body: JSON.stringify(body),
        })
            .then(this.handleResponse)
            .then((data) => {
                return data;
            });
    }

    async handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if (response.status === 401) {
                        signout();
                    }
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            } catch (err) {
                return Promise.reject(err);
            }
        });
    }

    getPaginationParams(page, perPage, sortColumn, sortDirection, filterSpec, filterText){
        return {
            page: page,
            per_page: perPage,
            sort_column: sortColumn,
            sort_direction: sortDirection,
            filter_spec: filterSpec,
            filter_text: filterText,
        }    
    }
}

export default API;
