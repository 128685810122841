import { Textarea, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export default function ParaTextInput({ value, onChange, placeholder }) {
    const boxBg = useColorModeValue("white", "blackAlpha.100");
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";

    const [v, setV] = useState(value);

    useEffect(() => {
        setV(value);
    }, [value]);

    const handleChange = (e) => {
        const { value } = e.target
        setV(value)
        if (onChange) onChange(value)
    }

    return <Textarea size="sm" minH={180} color={textColor} bg={boxBg} bgColor={boxBg} _placeholder={{ textColor: textColorSecondary }} type='text' placeholder={placeholder} value={v} onChange={handleChange} />
}