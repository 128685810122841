import API from './api';

export class RecommendationsService extends API {
    constructor() {
        super('admin/recommendations');
    }

    async scheduleRecommendations(accountId, entity) {
        return await this.post({
            body: { account_id: accountId, entity: entity }
        });
    }
}
