import {
  Box,
  useColorModeValue,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import MiniStatistics from "components/card/MiniStatistics";
import { DashboardService } from "services/dashboard_service"

export default function Dashboard() {
  const service = new DashboardService();
  const [loading, setLoading] = useState(true);
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [counts, setCounts] = useState({});
  useEffect(async () => {
    setLoading(true);
    const count = await service.getDashboardData();
    setCounts(count)
    setLoading(false);
  }, [])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loading && <Spinner color='blue.500' />}
      {!loading && <SimpleGrid
        columns={{ base: 1, md: 1, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          boxBg={boxBg}
          name='Total Accounts'
          value={counts.accounts_count.curr}
          prevValue={counts.accounts_count.prev}
        />
        <MiniStatistics
          boxBg={boxBg}
          name='Total Organizations'
          value={counts.orgs_count.curr}
          prevValue={counts.orgs_count.prev}
        />
        <MiniStatistics
          boxBg={boxBg}
          name='Total Users'
          value={counts.users_count}
        />
      </SimpleGrid>
      }
      <iframe width="100%" height="1000" src="https://datastudio.google.com/embed/reporting/d8f35f3f-6d71-4d0c-9954-ccf3a863e639/page/F8znC" style={{ border: 0 }} allowFullScreen></iframe>
    </Box>
  );
}
