import { OrganizationsService } from 'services/organizations_service';
import React, { useState } from 'react';
import { DEFAULT_REPORT_CONFIG } from 'constants';
import { Box, Button } from "@chakra-ui/react";
import ReportConfig from "./report_config"
import { AccountsService } from 'services/accounts_service';
import { catchAsync } from 'util';

export default function ConfigureCustomReport({ reportConfig = [], orgId, accountId }) {
  const orgservice = new OrganizationsService();
  const accservice = new AccountsService();
  const [reportConfigs, setReportConfigs] = useState(reportConfig)
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const addReportConfig = () => {
    setReportConfigs([...reportConfigs, DEFAULT_REPORT_CONFIG]);
  }
  const handleReportConfigChange = (index, updatedConfig) => {
    const report = [...reportConfigs];
    report[index] = updatedConfig;
    setReportConfigs(report)
  }

  const handleExpanded = (index) => {
    if (index == expandedIndex) {
      setExpandedIndex(-1);
    }
    else {
      setExpandedIndex(index);
    }
  }

  const handleDeleteConfig = (index) => {
    const report = [...reportConfigs];
    report.splice(index, 1);
    setReportConfigs(report)
  }

  const onSubmit = catchAsync(async () => {
    if (accountId)
      await accservice.updateAccount(accountId, { report_config: reportConfigs });
    else if (orgId)
      await orgservice.updateOrganization({ org_id: orgId, report_config: reportConfigs });
  }, "Custom Report updated successfully", "Error updating custom report");

  return (
    <Box>
      <div>{reportConfigs.map((report, index) => <div key={index}><ReportConfig reportConfig={report} onChange={(updatedConfig) => handleReportConfigChange(index, updatedConfig)} isExpanded={index == expandedIndex} label={`Report ${index + 1}`} onExpand={() => handleExpanded(index)} onDelete={() => handleDeleteConfig(index)} /> </div>)}</div>
      <Button onClick={addReportConfig} mt={2} > + Add Report Config</Button>
      <Button colorScheme='teal' mt={2} ml={2} onClick={onSubmit}>Configure Custom Report</Button>
    </Box>
  )
}