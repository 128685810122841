import React, { useState, useEffect } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { ReportValidationsService } from 'services/report_validations_service';
import CustomDataTable from "components/custom_data_table/custom_data_table";
import { RepeatClockIcon } from '@chakra-ui/icons';
import { catchAsync } from 'util';
import ConfirmableDeleteButton from 'components/buttons/confirmable_delete_button';
import { useTablePreferences } from 'contexts/table_preferences_contex';
import { useFilter } from 'contexts/filter_context';
import SearchBox from 'components/input/SearchBox';
import Loader from 'components/loader';
import { linkToAccount } from 'components/table_cells/table_cells';

export default function ReportValidations({ accountId }) {
    const [loading, setLoading] = useState(false);
    const service = new ReportValidationsService();
    const [pendingReports, setPendingReports] = useState([]);
    const { page, getPreferences, sortDetails, filters, setFilterText } = useTablePreferences()
    const { setFilterKey } = useFilter();
    const [totalRows, setTotalRows] = useState(0)
    const FILTER_KEY = "report_validations"
    const { perPage, filterText } = getPreferences(FILTER_KEY)

    useEffect(() => {
        setFilterKey(FILTER_KEY)
    }, [])

    const fetchPaginatedData = async (page, sortColumn, sortDirection, filterSpec, filterText) => {
        setLoading(true);
        try {
            const { records, count } = await service.getReportValidations({ accountId, page, perPage, sortColumn, sortDirection, filterSpec, filterText })
            setTotalRows(count);
            setPendingReports(records)
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);
    };

    const fetchData = async () => {
        fetchPaginatedData(page, sortDetails.column || "created", sortDetails.direction || "desc", JSON.stringify(filters[FILTER_KEY]), filterText)
    }

    useEffect(() => {
        fetchData()
    }, [perPage, page, sortDetails, filters, filterText]);


    const handleReschedule = catchAsync(async (reportId) => {
        await service.updateProcessingStatus(reportId, false);
        fetchData();
    }, 'Report Validations scheduled successfully', 'Error in rescheduling report validations');

    const columns = [
        {
            name: "Id",
            width: "220px",
            sortable: true,
            selector: (row) => row._id,
            sortField: "_id"
        },
        {
            name: "Processing",
            width: "140px",
            sortable: true,
            cell: (row) => <div>
                {row.processing === true && "Processing"}
                {row.processing === false && "Scheduled"}
                {row.processing === 'done' && "Processed"}
                {row.processing && <RepeatClockIcon ml={2} color="green.300" onClick={() => {
                    handleReschedule(row._id)
                }} />}
            </div>,
            selector: (row) => row.processing,
            sortField: "processing"
        },
        {
            name: "Account ID",
            cell: linkToAccount,
            width: "220px",
            sortable: true,
            selector: (row) => row.account_id,
            sortField: "account_id"
        },
        {
            name: "From Date",
            width: "100px",
            sortable: true,
            selector: (row) => row.from_dt,
            sortField: "from_dt"
        },
        {
            name: "To Date",
            width: "100px",
            sortable: true,
            selector: (row) => row.to_dt,
            sortField: "to_dt"
        },
        {
            name: "Record Type",
            width: "140px",
            sortable: true,
            selector: (row) => row.record_type,
            sortField: "record_type"
        },
        {
            name: "Campaign Type",
            width: "140px",
            sortable: true,
            selector: (row) => row.campaign_type,
            sortField: "campaign_type"
        },
        {
            name: "Segment",
            width: "100px",
            sortable: true,
            cell: (row) => row.segment ?? "--",
            selector: (row) => row.segment,
            sortField: "segment"
        },
        {
            name: "Tactic",
            width: "100px",
            sortable: true,
            cell: (row) => row.tactic ?? "--",
            selector: (row) => row.tactic,
            sortField: "tactic"
        },
        {
            name: "Cost",
            width: "300px",
            sortable: true,
            cell: (row) => <div> 
                                <div>API : {JSON.stringify(Math.round(row.api_metrics?.cost))}</div>
                                <div>ADB : {JSON.stringify(Math.round(row.adb_metrics?.cost))}</div>
                                <div>Change Percentage: <span style={{'color' : row.change_perc_metrics?.cost > 1 ? 'red' : 'green'}}>{JSON.stringify(Math.round(row.change_perc_metrics?.cost))} %</span></div>
                           </div> 
                           ?? "--",
            selector: (row) => JSON.stringify(row.api_metrics),
            sortField: "change_perc_metrics.cost"
        },
        {
            name: "Impressions",
            width: "300px",
            sortable: true,
            cell: (row) => <div> 
                                <div>API : {JSON.stringify(Math.round(row.api_metrics?.impressions))}</div>
                                <div>ADB : {JSON.stringify(Math.round(row.adb_metrics?.impressions))}</div>
                                <div>Change Percentage: <span style={{'color' : row.change_perc_metrics?.impressions > 1 ? 'red' : 'green'}}>{JSON.stringify(Math.round(row.change_perc_metrics?.impressions))} %</span></div>
                           </div> 
                           ?? "--",
            selector: (row) => JSON.stringify(row.api_metrics),
            sortField: "change_perc_metrics.impressions"
        },
        {
            name: "Clicks",
            width: "300px",
            sortable: true,
            cell: (row) => <div> 
                                <div>API : {JSON.stringify(Math.round(row.api_metrics?.clicks))}</div>
                                <div>ADB : {JSON.stringify(Math.round(row.adb_metrics?.clicks))}</div>
                                <div>Change Percentage: <span style={{'color' : row.change_perc_metrics?.clicks > 1 ? 'red' : 'green'}}>{JSON.stringify(Math.round(row.change_perc_metrics?.clicks))} %</span></div>
                           </div> 
                           ?? "--",
            selector: (row) => JSON.stringify(row.api_metrics),
            sortField: "change_perc_metrics.clicks"
        },
        {
            name: "Orders",
            width: "300px",
            sortable: true,
            cell: (row) => <div> 
                                <div>API : {JSON.stringify(Math.round(row.api_metrics?.orders))}</div>
                                <div>ADB : {JSON.stringify(Math.round(row.adb_metrics?.orders))}</div>
                                <div>Change Percentage: <span style={{'color' : row.change_perc_metrics?.orders > 1 ? 'red' : 'green'}}>{JSON.stringify(Math.round(row.change_perc_metrics?.orders))} %</span></div>
                           </div> 
                           ?? "--",
            selector: (row) => JSON.stringify(row.api_metrics),
            sortField: "change_perc_metrics.orders"
        },
        {
            name: "Sales",
            width: "300px",
            sortable: true,
            cell: (row) => <div> 
                                <div>API : {JSON.stringify(Math.round(row.api_metrics?.sales))}</div>
                                <div>ADB : {JSON.stringify(Math.round(row.adb_metrics?.sales))}</div>
                                <div>Change Percentage: <span style={{'color' : row.change_perc_metrics?.sales > 1 ? 'red' : 'green'}}>{JSON.stringify(Math.round(row.change_perc_metrics?.sales))} %</span></div>
                           </div> 
                           ?? "--",
            selector: (row) => JSON.stringify(row.api_metrics),
            sortField: "change_perc_metrics.sales"
        }
    ]

    return (
        <Box>
            {!loading && <SearchBox placeholder='Search ID' value={filterText} onEnter={(v) => setFilterText(v)} />}
            <CustomDataTable
                keyField="_id"
                progressPending={loading}
                progressComponent={<Loader />}
                columns={columns}
                data={pendingReports}
                filterKey={FILTER_KEY}
                remotePagination={true}
                paginationTotalRows={totalRows}
            />
        </Box>
    )
}

