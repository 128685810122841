import React, { useContext, createContext, useState, useEffect } from "react";
const pageStateContext = createContext();

function usePageState() {
    return useContext(pageStateContext);
}

function ProvidePageState({ children }) {
    const pageState = useProvidePageState();
    return (
        <pageStateContext.Provider value={pageState}>
            {children}
        </pageStateContext.Provider>
    );
}

function useProvidePageState(){
    const [pageTitle,setPageTitle] = useState("empty");
    return{
        pageTitle,setPageTitle
    }   
}

export {ProvidePageState,usePageState}