
import { Icon, Switch } from '@chakra-ui/react';
import CustomDataTable from 'components/custom_data_table/custom_data_table';
import { MdCircle } from 'react-icons/md';
import { AccountsService } from 'services/accounts_service';
import { catchAsync } from 'util';

export default function SPAccounts({ accounts, accountId, forceRefresh=()=>{} }) {
    const handleAccountUpdate = catchAsync(async (sp_info) => {
        await new AccountsService().updateAccount(accountId, { sp_info });
        await forceRefresh();
    }, 'SP Account updated successfully', 'Error updating SP Account');

    const columns = [
        {
            name: "Active",
            cell: ({ active }) => (
                <Icon
                    as={MdCircle}
                    width="8px"
                    height="8px"
                    color={active ? "green.400" : "red"}
                    margin={2}
                />
            ),
            width: "90px",
            sortable: true,
            selector: (row) => row.active,
        },
        {
            name: "Name",
            cell: ({ sp_name }) => <div>{sp_name}</div>,
            width: "220px",
            sortable: true,
            selector: (row) => row.sp_name,
        },
        {
            name: "Type",
            cell: ({ account_type }) => <div>{account_type}</div>,
            width: "220px",
            sortable: true,
            selector: (row) => row.account_type,
        },
        {
            name: "Fetch Data",
            cell: ({ fetch_data, ...row }) => <div>
                <Switch isChecked={fetch_data} onChange={(e)=>handleAccountUpdate({...row, fetch_data: e.target.checked})} />
            </div>,
            width: "220px",
            sortable: true,
            selector: (row) => row.account_type,
        },
        {
            name: "Brands",
            cell: ({ brands }) => <div>{brands.join(', ')}</div>,
            width: "220px",
        },
    ]

    return <CustomDataTable
        columns={columns}
        data={accounts}
        keyField="_id"
        pagination
    />
}