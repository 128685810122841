import React, { useState, useEffect } from 'react'
import { AccountsService } from 'services/accounts_service';
import { Box, Button, Grid, GridItem } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { useWizard } from 'components/wizard';
import { catchAsync } from 'util';
import Loader from 'components/loader';

export function DisableDaypartingCampaigns({ accountId }) {
  const service = new AccountsService();
  const [daypartingCampaignsCount, setDaypartingEnabledCampaignsCount] = useState();
  const { setCanGoNext, nextStep } = useWizard();
  const [loading, setLoading] = useState();

  const fetchData = catchAsync(async () => {
    setLoading(true)
    const { dayparting_camps_count } = await service.configureAccount({ account_id: accountId, flag: 'dayparting_camps' });
    setDaypartingEnabledCampaignsCount(dayparting_camps_count);
    setLoading(false)
  }, undefined, 'Error fetching dayparting data. Please try again later');

  useEffect(async () => {
    fetchData();
  }, [])

  useEffect(() => {
    if (daypartingCampaignsCount == 0) {
      nextStep()
    } else {
      setCanGoNext(false)
    }
  }, [daypartingCampaignsCount])

  const handleDisableDayparting = catchAsync(async () => {
    await service.configureAccount({ account_id: accountId, flag: 'disable_dayparting_camps' });
    fetchData();
  }, 'All dayparting campaigns disabled successfully', 'Error disabling dayparting campaigns')

  return (
    <Box margin={2}>
      {loading ? <Loader /> : <div>
        {daypartingCampaignsCount == 0 && <ul><li>No Campaigns have dayparting applied, you can proceed.</li></ul>}
        {daypartingCampaignsCount != 0 &&
          <Box>
            <ul>
              <li>{daypartingCampaignsCount} campaigns have dayparting applied to them in this account.</li>
            </ul>
            <Button onClick={handleDisableDayparting} colorScheme='teal' marginTop={4}>Disable Dayparting</Button>
          </Box>}
      </div>}
    </Box>
  )
}
