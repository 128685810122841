import React, { useContext, createContext, useState, useEffect } from "react";
const filterContext = createContext();

function useFilter() {
    return useContext(filterContext);
}

function ProvideFilter({ children }) {
    const filter = useProvideFilter();
    return(
        <filterContext.Provider value={filter}>
            {children}
        </filterContext.Provider>
    )
}

function useProvideFilter() {

    const [filterType, setFilterType] = useState()
    const [filterKey, setFilterKey] = useState()
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters") || "{}"))
    const config = {

    }

    useEffect(() => {
        try {
            setFilters(JSON.parse(localStorage.getItem("filters")|| "{}"))
        } catch (e) {
            console.log(e)
        }
    },[])

    useEffect(() => {
        localStorage.setItem("filters",JSON.stringify(filters))
    }, [filters])

    const getFilters = () => {
        retrun (filters[filterKey] || [])
    }

    const addFilter = (filter, fk) => {
        const arr = (filters[fk || filterKey] || [])
        arr.push(filter)
        filters[fk || filterKey] = [...arr]
        setFilters({...filters})
    }

    const removeFilter = (idx) => {
        const arr = (filters[filterKey] || [])
        arr.splice(idx, 1)
        filters[filterKey] = [...arr]
        setFilters({...filters})
    }

    const removeFilterForAttribute = (attr) => {
        const filter_array = (filters[filterKey] || [])
        const arr = filter_array.filter((filter) => filter.attribute !== attr)
        filters[filterKey] = [...arr]
        setFilters({...filters})
    }

    const resetFilters = (fk) => {
        filters[fk || filterKey] = []
        setFilters({...filters})
    }

    const evaluate = (row) => {
        const filtersArr = getFilters(filterKey)
        if(filtersArr.length === 0) return true;
        let result = true;
        for(let f of filtersArr) {
            const{attribute, operator, value, label} = f;
            const{eval_fn,textual,evaluators={}, multi=false} = config[label] || {}
            let r;
            if(evaluators[filterKey]) {
                r = evaluators[filterKey](row, value)
            }else if(eval_fn) {
                r = eval_fn(row, value)
            }else if(operator == "=") {
                if (typeof(row[attribute]) === "object") {
                    r = (row[attribute] || []).map((v) => String(v)).indexOf(String(value)) > -1
                } else if(multi) {
                    r = value.map(a => String(a).indexOf(String(row[attribute]))) > -1
                } else if(textual){
                    r = (row[attribute] || "").toLowerCase() == (value || "").toLowerCase()
                } else {
                    r = row[attribute] == value
                }
            } else if(operator == ">") {
                r = row[attribute] > value
            }else if(operator == ">=") {
                r = row[attribute] >= value
            }else if(operator == "<") {
                r = row[attribute] < value
            }else if (operator == "<=") {
                r = row[attribute] <= value
            } else if (operator == 'in') {
                const arg = textual ? row[attribute]?.toLowerCase() : row[attribute]
                r = arg?.indexOf(value) > -1
            }
            result = result && r
        }
        return result
    }
    return {
        addFilter,
        filterType,
        setFilterType,
        filters,
        getFilters,
        removeFilter,
        removeFilterForAttribute,
        resetFilters,
        evaluate,
        config,
        setFilterKey,
        filterKey    
    }
}

export {
    useFilter,
    ProvideFilter
}