import React,{ useState } from 'react'
import { Button} from '@chakra-ui/react'
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { OrganizationsService } from 'services/organizations_service';
import Swal from 'sweetalert2'
import BasicModal from 'components/modals/basic';


export function ScheduleBilling({orgId}) {

  const service = new OrganizationsService();
  const [rangeStart, setRangeStart] = useState(moment().subtract(30, 'days').toDate());
  const [rangeEnd, setRangeEnd] = useState(moment().toDate());
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  
  const handleDateRangeChange = (data) => {
    if (data?.startDate != rangeStart) setRangeStart(data?.startDate);
    if (data?.endDate != rangeEnd) setRangeEnd(data?.endDate);
  }

  async function handleonSubmit(){
    try{
      await service.scheduleBilling(orgId, moment(rangeStart).format('YYYYMMDD'), moment(rangeEnd).format('YYYYMMDD'))
      Swal.fire({
        title: 'Success!',
        text: 'Billing Generation has been scheduled',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    }
    catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

  }

  return (
    <div>
      <span style={{border: '1px solid', padding: '5px', borderRadius: '5px'}} onClick={() => setShowDateRangeModal(true)}>{moment(rangeStart).format("DD/MM/YYYY")} - {moment(rangeEnd).format("DD/MM/YYYY")}</span>
      <Button onClick={handleonSubmit} colorScheme='teal' margin={4}>Process Billing</Button>
      <BasicModal isOpen={showDateRangeModal} onClose={()=>setShowDateRangeModal(false)}>
        <DateRangePicker
          onChange={item => handleDateRangeChange(item.dateRange)}
          ranges={[{ startDate: rangeStart, endDate: rangeEnd, key: 'dateRange' }]}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          direction="horizontal"
        />
        <Button onClick={()=>setShowDateRangeModal(false)} colorScheme='teal' margin={4}>Save</Button>
      </BasicModal>
    </div>
  )
}
