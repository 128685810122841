import { AccountsService } from "services/accounts_service";
import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useWizard } from 'components/wizard';
import Loader from "components/loader";
import { catchAsync } from 'util';

export default function DeactivateBudgetTasks({ accountId }) {
    const service = new AccountsService();
    const [tasksCount, setTasksCount] = useState();
    const { setCanGoNext, nextStep } = useWizard();
    const [loading, setLoading] = useState();

    const fetchData = catchAsync(async () => {
        setLoading(true);
        const { tasks_count } = await service.configureAccount({ account_id: accountId, flag: 'tasks' });
        setTasksCount(tasks_count);
        setLoading(false);
    }, undefined, 'Error fetching budget tasks data. Please try again later');

    useEffect(async () => {
        fetchData();
    }, [])

    useEffect(() => {
        // if (tasksCount == 0) {
        //     nextStep();
        // } else {
        //     setCanGoNext(false);
        // }
        nextStep()
    }, [])

    return <Box margin={2}>
        {loading ? <Loader /> : <div>
            {tasksCount == 0 && <ul><li>No Active Budget Management Tasks , you can proceed.</li></ul>}
            {!tasksCount == 0 && <ul><li>Active Budget Management Tasks exist for this account, please de-link them through the UI</li></ul>}
        </div>}
    </Box>
}