import { Menu, MenuButton, MenuList, MenuItem, Button, Portal } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons';
import Swal from 'sweetalert2'

export default function DropdownButton({ label, actions }) {
    const handleOnClick = (onConfirm) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed && onConfirm) {
                onConfirm()
            }
        })
    }

    return <Menu isLazy>
        <MenuButton colorScheme='teal' as={Button} rightIcon={<ChevronDownIcon />}>
            {label}
        </MenuButton>
        <Portal>
            <MenuList>
                {actions.map((action, index) => <MenuItem key={index} onClick={action.confirmable ? () => handleOnClick(action.onClick) : action.onClick}>{action.label}</MenuItem>)}
            </MenuList>
        </Portal>
    </Menu>
}