import { Box, Button, Grid, GridItem } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { catchAsync } from 'util';
import FormSelect from "components/select/FormSelect";
import { OrganizationsService } from "services/organizations_service";
import { AccountsService } from 'services/accounts_service';
import Loader from 'components/loader';
import TextInput from "components/input/TextInput";




export default function ScheduleImpactAnalysis({ onComplete, accountId }) {
    const service = new AccountsService();
    const [loading, setLoading] = useState();
    const [rulesetId, setRulesetId] = useState()
    const [strategyId, setStrategyId] = useState()
    const [days, setDays] = useState()
    


    const handleSubmit = catchAsync(async() => {
        setLoading(true)
        onComplete()
        await service.scheduleImpactAnalysis(accountId, rulesetId, strategyId, days)
        setLoading(false)
    }, "Impact Analysis Scheduled Successfully", "Error scheduling Impact Analysis");

    return <Box>
        {loading ? <Loader /> : <div>
            <div>Ruleset ID</div>
            <TextInput onChange={(e)=>{setRulesetId(e)}} />
            <div>Strategy ID</div>
            <TextInput onChange={(e)=>{setStrategyId(e)}} />
            <div>Days</div>
            <TextInput onChange={(e)=>{setDays(e)}} />
            <Button mt={4} colorScheme='teal' onClick={handleSubmit}>Submit</Button>
        </div>}
        </Box>
}