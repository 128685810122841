import React, { useState, useEffect } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { PendingSnapshotsService } from 'services/pending_snapshots_service';
import CustomDataTable from "components/custom_data_table/custom_data_table";
import { RepeatClockIcon } from '@chakra-ui/icons';
import { catchAsync } from 'util';
import ConfirmableDeleteButton from 'components/buttons/confirmable_delete_button';
import { useTablePreferences } from 'contexts/table_preferences_contex';
import { useFilter } from 'contexts/filter_context';
import SearchBox from 'components/input/SearchBox';
import Loader from 'components/loader';
import { linkToAccount } from 'components/table_cells/table_cells';

export default function PendingSnapshots({ accountId }) {
    const [loading, setLoading] = useState(false);
    const service = new PendingSnapshotsService();
    const [pendingSnapshots, setPendingSnapshots] = useState([]);
    const { page, getPreferences, sortDetails, filters, setFilterText } = useTablePreferences()
    const { setFilterKey } = useFilter();
    const [totalRows, setTotalRows] = useState(0)
    const FILTER_KEY = "pending_snapshots"
    const { perPage, filterText } = getPreferences(FILTER_KEY)
    const [metaId, setMetaId] = useState()

    useEffect(() => {
        setFilterKey(FILTER_KEY)
    }, [])

    const fetchPaginatedData = async (page, sortColumn, sortDirection, filterSpec, filterText) => {
        setLoading(true);
        try {
            const { records, count } = await service.getPendingSnapshots({ metaId, accountId, page, perPage, sortColumn, sortDirection, filterSpec, filterText })
            setTotalRows(count);
            setPendingSnapshots(records)
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);
    };

    const fetchData = async () => {
        fetchPaginatedData(page, sortDetails.column || "created", sortDetails.direction || "desc", JSON.stringify(filters[FILTER_KEY]), filterText)
    }

    useEffect(() => {
        fetchData()
    }, [perPage, page, sortDetails, filters, filterText, metaId]);

    const handleDelete = catchAsync(async (snapshotId) => {
        await service.deletePendingReport(snapshotId);
        fetchData();
    }, 'Pending snapshot deleted successfully', 'Error in deleting pending snapshot');

    const handleReschedule = catchAsync(async (snapshotId) => {
        await service.updateProcessingStatus(snapshotId, false);
        fetchData();
    }, 'Pending snapshot scheduled successfully', 'Error in rescheduling pending snapshot');

    const columns = [
        {
            name: "Id",
            width: "220px",
            sortable: true,
            selector: (row) => row._id,
            sortField: "_id",
        },
        {
            name: "Account ID",
            cell: linkToAccount,
            width: "220px",
            sortable: true,
            selector: (row) => row.account_id,
            sortField: "account_id",
        },
        {
            name: "Record Type",
            width: "140px",
            sortable: true,
            selector: (row) => row.record_type,
            sortField: "record_type",
        },
        {
            name: "Campaign Type",
            width: "140px",
            sortable: true,
            selector: (row) => row.campaign_type,
            sortField: "campaign_type",
        },
        {
            name: "Created",
            width: "160px",
            sortable: true,
            selector: (row) => row.created,
            sortField: "created",
        },
        {
            name: "Processing",
            width: "140px",
            sortable: true,
            cell: (row) => <div>
                {row.processing ? 'True' : 'False'}
                {row.processing && <RepeatClockIcon ml={2} color="green.300" onClick={() => {
                    handleReschedule(row._id)
                }} />}
            </div>,
            selector: (row) => row.processing,
            sortField: "processing",
        },
        {
            name: 'Actions',
            width: '100px',
            cell: (row) => <ConfirmableDeleteButton onConfirm={()=> handleDelete(row._id)} />,
        }
    ]

    return (
        <Box>
            {!loading && <SearchBox placeholder="Meta ID" value={metaId} onEnter={(v) => setMetaId(v)} />}
            {!loading && <SearchBox placeholder='Search ID' value={filterText} onEnter={(v) => setFilterText(v)} />}
            <CustomDataTable
                keyField="_id"
                progressPending={loading}
                progressComponent={<Loader />}
                columns={columns}
                data={pendingSnapshots}
                filterKey={FILTER_KEY}
                remotePagination={true}
                paginationTotalRows={totalRows}
            />
        </Box>
    )
}

