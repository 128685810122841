import { Button } from '@chakra-ui/react';
import BasicModal from 'components/modals/basic';
import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

export default function ModalDateRangePicker({ value, onChange }) {
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [startDate, setStartDate] = useState(value?.start);
    const [endDate, setEndDate] = useState(value?.end);

    const handleDateRangeChange = (data) => {
        if (data?.startDate != startDate) setStartDate(data?.startDate);
        if (data?.endDate != endDate) setEndDate(data?.endDate);
    }

    const handleSave = () => {
        onChange({ start: startDate, end: endDate });
        setShowDateRangeModal(false);
    }

    return <div>
        <span style={{ border: '1px solid', padding: '5px', borderRadius: '5px' }} onClick={() => setShowDateRangeModal(true)}>{moment(startDate).format("DD/MM/YYYY")} - {moment(endDate).format("DD/MM/YYYY")}</span>
        <BasicModal isOpen={showDateRangeModal} onClose={() => setShowDateRangeModal(false)}>
            <DateRangePicker
                onChange={item => handleDateRangeChange(item.dateRange)}
                ranges={[{ startDate, endDate, key: 'dateRange' }]}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
            />
            <Button onClick={handleSave} colorScheme='teal' margin={4}>Save</Button>
        </BasicModal>
    </div>
}