import React, { useState, useEffect } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import CustomDataTable from "components/custom_data_table/custom_data_table";
import { AutomatedRunsService } from 'services/automated_runs_service';
import { useTablePreferences } from 'contexts/table_preferences_contex';
import { useFilter } from 'contexts/filter_context';
import SearchBox from 'components/input/SearchBox';
import Loader from 'components/loader';
import { linkToAccount, linkToCampaign, linkToRuleSet } from 'components/table_cells/table_cells';

export default function AutomatedRuns({ accountId }) {
    const [loading, setLoading] = useState(false);
    const service = new AutomatedRunsService();
    const [automatedRuns, setAutomatedRuns] = useState([]);
    const { page, getPreferences, sortDetails, filters, setFilterText } = useTablePreferences()
    const { setFilterKey } = useFilter();
    const [totalRows, setTotalRows] = useState(0)
    const FILTER_KEY = "automated_runs"
    const { perPage, filterText } = getPreferences(FILTER_KEY)
    const [runId, setRunId] = useState()

    useEffect(() => {
        setFilterKey(FILTER_KEY)
    }, [])

    const fetchPaginatedData = async (page, sortColumn, sortDirection, filterSpec, filterText) => {
        setLoading(true);
        try {
            const { records, count } = await service.getFailedRuns({ accountId, runId, runStatus: filterText ? undefined : 'failed', page, perPage, sortColumn, sortDirection, filterSpec, filterText })
            setTotalRows(count);
            setAutomatedRuns(records)
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);
    };

    const fetchData = async () => {
        fetchPaginatedData(page, sortDetails.column || "dt", sortDetails.direction || "desc", JSON.stringify(filters[FILTER_KEY]), filterText)
    }

    useEffect(() => {
        if (filterText || runId) {
            fetchData()
        }
    }, [perPage, page, sortDetails, filters, filterText, runId]);

    const columns = [
        {
            name: "Status",
            width: "100px",
            sortable: true,
            selector: (row) => row.status,
            sortField: "status",
        },
        {
            name: "Attempts",
            width: "100px",
            sortable: true,
            selector: (row) => row.attempts,
            sortField: "attempts",
        },
        {
            name: "Days",
            width: "100px",
            sortable: true,
            cell: (row) => row.days ?? '--',
            selector: (row) => row.days,
            sortField: "days",
        },
        {
            name: "Id",
            width: "220px",
            sortable: true,
            selector: (row) => row._id,
            sortField: "_id",
        },
        {
            name: "Account ID",
            cell: linkToAccount,
            width: "220px",
            sortable: true,
            selector: (row) => row.account_id,
            sortField: "account_id",
        },
        {
            name: "Run Type",
            width: "150px",
            sortable: true,
            selector: (row) => row.run_type,
            sortField: "run_type",
        },
        {
            name: "Campaign ID",
            cell: linkToCampaign,
            width: "150px",
            sortable: true,
            selector: (row) => row.campaign_id,
            sortField: "campaign_id",
        },
        {
            name: "Adgroup ID",
            width: "150px",
            sortable: true,
            selector: (row) => row.adgroup_id,
            sortField: "adgroup_id",
        },
        {
            name: "Ruelset ID",
            cell: linkToRuleSet,
            width: "220px",
            sortable: true,
            selector: (row) => row.ruleset_id,
            sortField: "ruleset_id",
        },
        {
            name: "Strategy ID",
            width: "220px",
            sortable: true,
            selector: (row) => row.strategy_id,
            sortField: "strategy_id",
        },
        {
            name: "Task ID",
            width: "220px",
            sortable: true,
            selector: (row) => row.task_id ?? row.task_id_str,
            sortField: "task_id",
        },
        {
            name: "Created",
            width: "160px",
            sortable: true,
            selector: (row) => row.dt,
            sortField: "dt",
        },
        {
            name: "TA Date",
            width: "120px",
            sortable: true,
            selector: (row) => row.timezone_aware_date,
            sortField: "timezone_aware_date",
        },
        {
            name: "TA Hour",
            width: "100px",
            sortable: true,
            selector: (row) => row.timezone_aware_hour,
            sortField: "timezone_aware_hour",
        },
        {
            name: "Last Updated",
            width: "160px",
            sortable: true,
            selector: (row) => row.last_updated,
            sortField: "last_updated",
        },
        {
            name: "Action",
            width: "160px",
            sortable: true,
            cell: (row) => row.action ? JSON.stringify(row.action) : '--',
            selector: (row) => row.action,
            sortField: "action",
        },
        {
            name: "Bids updated",
            width: "160px",
            sortable: true,
            cell: (row) => row.bids_updated ?? '--',
            selector: (row) => row.bids_updated,
            sortField: "bids_updated",
        },
        {
            name: "Campaigns updated",
            width: "160px",
            sortable: true,
            cell: (row) => row.campaigns_updated ?? '--',
            selector: (row) => row.campaigns_updated,
            sortField: "campaigns_updated",
        },
       
    ]

    return (
        <Box>
            {!loading && <SearchBox placeholder="Run ID" value={runId} onEnter={(v) => setRunId(v)} />}
            {!loading && <SearchBox placeholder='Search ID' value={filterText} onEnter={(v) => setFilterText(v)} />}
            <CustomDataTable
                keyField="_id"
                progressPending={loading}
                progressComponent={<Loader />}
                columns={columns}
                data={automatedRuns}
                filterKey={FILTER_KEY}
                remotePagination={true}
                paginationTotalRows={totalRows}
            />
        </Box>
    )
}

