import Swal from 'sweetalert2'
import { DeleteIcon } from '@chakra-ui/icons';

export default function ConfirmableDeleteButton({ onConfirm }) {
    const handleOnClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed && onConfirm) {
                onConfirm()
            }
        })
    }

    return <DeleteIcon color="red.500" onClick={handleOnClick} />
}