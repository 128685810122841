import API from './api';

export class CampaignStrategyTemplateService extends API {
    constructor() {
      super('admin/strategy_template');
    }

    async createUpdateStrategyTemplate(templateName, templateDescription, strategyId, active, templateId) {
      return await this.post({
        body: { template_name: templateName, template_description: templateDescription, strategy_id: strategyId, is_active: active, _id: templateId },      
    })
    }
    async getStrategyTemplate() {
      return await this.get();
    }
    async deleteStrategyTemplate(templateId) {
      return await this.delete({
        params: { template_id: templateId }
      });
    }
}