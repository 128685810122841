import { GetLinkURLService } from "services/get_link_url_service"

export function linkToAccount({account_id}, idx) {
    return (
        <div className={`account-${idx}-name mb-1 p-2`}>
            <a className="inline-anchor" style = {{color:'blue',cursor:'pointer'}} href={`/admin/accounts/${account_id}`}>
                {account_id} 
            </a>
        </div>
    )    
}

export const linkToCampaign = ({campaign_id, campaign_type}, idx) => {
    const service = new GetLinkURLService()
    const campaignType = campaign_type ? campaign_type : "sp"
    const link = service.getComposedPath(`/campaigns/${campaignType}/${campaign_id}`)
    return (
        <div className={`account-${idx}-name mb-1 p-2`}>
            <a className="inline-anchor" style = {{color:'blue',cursor:'pointer'}} href={`${link}`} target="_blank">
                {campaign_id} 
            </a>
        </div>
    )
}

export const linkToRuleSet = ({ruleset_id}, idx) => {
    const service = new GetLinkURLService()
    const link = service.getComposedPath(`/rulesets/${ruleset_id}/edit`)
    return (
        <div className={`account-${idx}-name mb-1 p-2`}>
            <a className="inline-anchor" style = {{color:'blue',cursor:'pointer'}} href={`${link}`} target="_blank" rel="noopener noreferrer">
                {ruleset_id} 
            </a>
        </div>
    )
}