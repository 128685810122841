import React, { useState, useEffect } from 'react';
import { UsageService } from 'services/usage_service';
import { Box, Spinner } from '@chakra-ui/react';

export function AccountUsage({ accountId }) {
  const service = new UsageService();
  const [usage, setUsage] = useState({});
  const [loading, setLoading] = useState();

  useEffect(async () => {
    setLoading(true);
    try {
      const usage = await service.getAccountUsage(accountId);
      setUsage(usage);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [])

  return (
    <div>
      {loading && <Spinner />}
      {!loading && <Box>
        <b>Total Successfull Automated Runs: </b><br />
        {usage.success_automations_count}<br />
        <b>Total Processing Automated Runs: : </b><br />
        {usage.processing_automations_count}<br />
        <b>Total Changes : </b><br />
        {usage.changes_count}<br />
        <b>Total Rulesets : </b><br />
        {usage.rulesets_count}<br />
      </Box>}
    </div>
  )
}
