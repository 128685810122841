import { Box } from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel, Spinner } from '@chakra-ui/react'
import { usePageState } from "contexts/page_state_context";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrganizationsService } from "services/organizations_service";
import OrganizationOverview from "./overview";
import Accounts from "features/accounts";
import Users from "features/users";
import { organizationTabs } from "constants";

export default function Organization() {
  const service = new OrganizationsService();
  let {orgId, tabKey} = useParams();
  const [organization, setOrganization] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (tabKey) {
      const tab = organizationTabs.find(t => t.key === tabKey);
      if (tab) {
        setActiveTab(tab.index);
      }
    }
  }, [])

  const { setPageTitle } = usePageState();

  const fetchData = async () => {
    setLoading(true);
    const { organization } = await service.getOrganization(orgId);
    setOrganization(organization)
    setLoading(false);
  }
  useEffect(async () => {
    fetchData()
  }, [])

  useEffect(() => {
    if (organization == undefined) {
      setPageTitle("")
    } else {
      setPageTitle(organization.name)
    }
  }, [organization])

  const handleTabChange = (index) => {
    const tabKey = organizationTabs.find(tab => tab.index === index).key;
    window.history.replaceState(null, "", `/admin/organizations/${orgId}/${tabKey}`)
    setActiveTab(index);
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loading && <Spinner color='blue.500' />}
      {!loading && <Tabs size="md" variant="enclosed" isLazy index={activeTab} onChange={handleTabChange}>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Accounts</Tab>
          <Tab>Users</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <OrganizationOverview organization={organization} forceRefresh={fetchData} />
          </TabPanel>
          <TabPanel>
            <Accounts orgId={orgId} inTab={true} />
          </TabPanel>
          <TabPanel>
            <Users orgId={orgId} inTab />
          </TabPanel>
        </TabPanels>
      </Tabs>}
    </Box>
  );
}